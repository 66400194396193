/* eslint-disable @typescript-eslint/no-unused-vars */
import uniq from 'lodash/uniq'
import React, { useState } from 'react'
import styled from 'styled-components'

import { assessmentMaterialsResources } from 'dashboards/constant/resources'
import { AllowedQuestionnaireKeys } from 'dashboards/public/PublicQuestionnaire'
import { Payload } from 'recharts/types/component/DefaultTooltipContent'
import {
  QuestionnaireBooklet,
  QuestionnaireSummarySaved,
  QuestionnaireKey,
  QuestionnaireUser,
  questionnaireBookletLabels,
  questionnaireKeyLabels,
  questionnaireTypeTotalScoreMap,
  questionnaireKeyLookup,
} from 'shared/questionnaires/types'

import { ButtonLink } from 'common/LinkButton'
import { H1, Row } from 'common/ui'
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis } from 'recharts'

import { InfoTooltip } from 'common/Tooltip'
import { useMeasure } from 'utils/useMeasure'
import { parseScore } from './QuestionnaireRows'

const dataCategoryColorMap = {
  cadet: '#18A0FB',
  teacher: '#FF8A00',
  parent: '#4EBE40',
  facilitator: '#011A46',
  custom: '#747C9B',
  unknown: '#cccccc',
}

const getMaxScore = (questionnaireSummaries: QuestionnaireSummarySaved[], type: QuestionnaireKey, name?: string) => {
  const items = questionnaireSummaries.filter(
    // Note: there maaaay be cases where questionnaire summary questionnaire_type is not a questionnaireKey type but just a questionnaire type...
    // not sure if this is going to bite me in the ass later
    (item) => item.questionnaire_type === type && (name ? item.name === name : true)
  )
  const scores = items
    .map(({ manual_score, score }) =>
      typeof parseScore(manual_score) === 'number' ? parseScore(manual_score) : typeof score === 'number' ? score : null
    )
    .filter((value) => value !== null)
  return (scores as number[]).sort((a, b) => b - a)[0]
}

const findAnyTotalValue = (questionnaireSummaries: QuestionnaireSummarySaved[], type: AllowedQuestionnaireKeys) => {
  if ((type as string) in questionnaireTypeTotalScoreMap)
    return questionnaireTypeTotalScoreMap[type as unknown as keyof typeof questionnaireTypeTotalScoreMap]
  const totals = questionnaireSummaries
    // Note: there maaaay be cases where questionnaire summary questionnaire_type is not a questionnaireKey type but just a questionnaire type...
    // not sure if this is going to bite me in the ass later
    .filter((item) => item.questionnaire_type === type)
    .map((item) => item.total)
    .filter((val) => val !== null)
  if (!totals.length) return null
  return totals[0]
}

const getQuestionnaireTypeOrCustomLabel = (anyType: string | AllowedQuestionnaireKeys) => {
  const isCustom = !!anyType.match(/^custom_/)
  return isCustom ? anyType.replace(/^custom_/, '') : questionnaireKeyLabels[anyType as QuestionnaireKey]
}

const getDataCategoryFromQuestionnaireType = (
  anyType: string | AllowedQuestionnaireKeys
): QuestionnaireUser | 'custom' | 'unknown' => {
  if (!!anyType.match(/^custom_/)) return 'custom'
  if (anyType in questionnaireKeyLookup)
    return questionnaireKeyLookup[anyType as AllowedQuestionnaireKeys].userType || 'unknown'
  return 'unknown'
}

interface ChartFilter {
  label: string
  types: QuestionnaireKey[] | null
}

const chartFilters: ChartFilter[] = [
  { label: 'All', types: null },
  { label: 'SSQ', types: ['spence-ssq-parent', 'spence-ssq-teacher'] },
  { label: 'ERSSQ', types: ['erssq-parent', 'erssq-teacher', 'erssq2-parent', 'erssq2-teacher'] },
  { label: 'Cadet', types: ['dylan-anger', 'james-anxiety'] },
  //   { label: 'Observational', types: ['observational-code'] },
  { label: 'Custom', types: ['custom'] },
]

interface Props {
  questionnaireSummaries: QuestionnaireSummarySaved[]
}

export const AssessmentChart: React.FC<Props> = ({ questionnaireSummaries }) => {
  const [activeFilter, setActiveFilter] = useState<ChartFilter>(chartFilters[0])
  const [measureContainer, { width }] = useMeasure()
  const questionnaireKeys: AllowedQuestionnaireKeys[] = (
    Object.keys(questionnaireKeyLookup) as AllowedQuestionnaireKeys[]
  ).filter((val) => (activeFilter.types === null ? val.indexOf('feedback') < 0 : activeFilter.types.includes(val)))
  const customTypes =
    activeFilter.types === null || activeFilter.types.includes('custom')
      ? uniq(
          questionnaireSummaries
            .filter(({ questionnaire_type }) => questionnaire_type === 'custom')
            .map(({ name }) => 'custom_' + name)
        )
      : []
  const allTypes: (AllowedQuestionnaireKeys | string)[] = [...questionnaireKeys, ...customTypes]

  const actualValues: { [key: number]: { [key: string]: number | null } } = {}
  const data = assessmentMaterialsResources.map(({ booklet }) =>
    allTypes.reduce(
      (obj, type, i) => {
        const isCustom = !!type.match(/^custom_/)
        const unprefixedType = isCustom ? type.replace(/^custom_/, '') : type
        const questionnaireSummary = questionnaireSummaries.find(
          ({ questionnaire_type, questionnaire_booklet, name, score, manual_score }) =>
            (['number', 'string'].includes(typeof manual_score) || typeof score === 'number') &&
            questionnaire_booklet === booklet &&
            questionnaire_type === (isCustom ? 'custom' : type) &&
            (isCustom ? name === unprefixedType : true)
        )
        if (!questionnaireSummary) return obj
        const { questionnaire_type, score, total } = questionnaireSummary
        const manual_score = parseScore(questionnaireSummary.manual_score)
        const observational_code_score = parseScore(questionnaireSummary.observational_code_score)
        const realScore =
          typeof manual_score === 'number' && manual_score > 0
            ? manual_score
            : questionnaire_type === 'observational-code' && typeof observational_code_score === 'number'
              ? observational_code_score
              : questionnaire_type !== 'observational-code' && typeof score === 'number'
                ? score
                : null
        const realTotal = isCustom
          ? getMaxScore(questionnaireSummaries, 'custom', unprefixedType)
          : questionnaire_type === 'observational-code'
            ? 3
            : total ||
              findAnyTotalValue(questionnaireSummaries, type as AllowedQuestionnaireKeys) ||
              getMaxScore(questionnaireSummaries, type as AllowedQuestionnaireKeys)
        if (!(typeof realScore === 'number' && realTotal))
          console.log(
            'missing a score or total',
            { score: realScore, total: realTotal, type, booklet },
            questionnaireSummary
          )
        const value = typeof realScore === 'number' && realTotal ? realScore / realTotal : 0
        actualValues[booklet] = { ...actualValues[booklet], [type as string]: realScore }
        return { ...obj, [type as string]: value }
      },
      { booklet } as { booklet: number; [key: string]: number }
    )
  )

  /*
  const [overrideWidth, setOverrideWidth] = useState<number | null>(null)
  const isPrinting = useMedia('print')

  useEffect(() => {
    console.log({ isPrinting })
    if (isPrinting) setOverrideWidth(500)
    else setOverrideWidth(null)
  }, [isPrinting])

  useEffect(() => {
    const handleBeforePrint = () => {
      console.log('before print triggered')
      setOverrideWidth(500)
    }
    const handleAfterPrint = () => {
      console.log('after print triggered')
      setOverrideWidth(null)
    }
    window.addEventListener('onbeforeprint', handleBeforePrint)
    window.addEventListener('onafterprint', handleAfterPrint)
    return () => {
      window.removeEventListener('onbeforeprint', handleBeforePrint)
      window.removeEventListener('onafterprint', handleAfterPrint)
    }
  }, [])
  */

  return (
    <>
      <Row alignItems="center">
        <H1 style={{ marginTop: -3 }}>Assessment over time</H1>
        <InfoTooltip placement="right" content="Filter view by selecting questionnaire titles above the graph." />
      </Row>
      <Row justifyContent="space-between" marginTop="m" marginBottom="m">
        {chartFilters.map((chartFilter) => (
          <FilterLink
            key={chartFilter.label}
            role="button"
            children={chartFilter.label}
            onClick={() => setActiveFilter(chartFilter)}
            active={activeFilter.label === chartFilter.label}
          />
        ))}
      </Row>
      <ChartContainer ref={measureContainer}>
        <LineChart width={/*overrideWidth || */ width} height={300} data={data} style={{ backgroundColor: 'white' }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="booklet"
            tickFormatter={(booklet: QuestionnaireBooklet, i) =>
              questionnaireBookletLabels[booklet] || `Booklet ${i + 1}`
            }
            padding={{ left: 50, right: 50 }}
          />
          <Tooltip
            formatter={(
              value: number,
              anyType: string | AllowedQuestionnaireKeys,
              props: Payload<typeof value, string>
            ) => {
              const label = getQuestionnaireTypeOrCustomLabel(anyType)
              const realValue = actualValues[props.payload.booklet]?.[anyType]
              const output = typeof realValue === 'number' ? String(realValue) : `${(value * 100).toFixed(2)}%`
              return [output, label]
            }}
            labelFormatter={(label: QuestionnaireBooklet, payload) => questionnaireBookletLabels[label] || `Booklet ?`}
            labelStyle={{ marginBottom: 10 }}
            itemStyle={{ fontSize: 12 }}
            itemSorter={(item: Payload<number, string>) => (typeof item.value !== 'number' ? 0 : 1 - item.value)}
          />
          <Legend
            formatter={(value: string | AllowedQuestionnaireKeys) => getQuestionnaireTypeOrCustomLabel(value)}
            iconSize={12}
            wrapperStyle={{ fontSize: 12, lineHeight: 1.6 }}
          />
          {(allTypes as string[])
            .sort((a, b) => {
              const catA = getDataCategoryFromQuestionnaireType(a)
              const catB = getDataCategoryFromQuestionnaireType(b)
              if (catA === 'custom') return 1
              return catA === catB ? 0 : catB > catA ? 1 : -1
            })
            .map((anyType, i) => {
              const dataCategory = getDataCategoryFromQuestionnaireType(anyType)
              const stroke = dataCategoryColorMap[dataCategory]
              return (
                <Line key={anyType} type="monotone" connectNulls dataKey={anyType} stroke={stroke} strokeWidth={2.5} />
              )
            })}
        </LineChart>
      </ChartContainer>
    </>
  )
}

const FilterLink = styled(ButtonLink)<{ active: boolean }>`
  color: ${(p) => (p.active ? '#011A46' : '#BDC3E0')};
  text-decoration: ${(p) => (p.active ? 'underline' : 'none')};
`

const ChartContainer = styled.div`
  page-break-inside: avoid;
  transform-origin: 0 0;
  @media print {
    page-break-inside: avoid;
    transform: scale(0.75);
  }
`
