import { ActionTypes } from './actionTypes'
import { IdField, UidField, OrderField, TitleField, OwnerOccupierFields, TimestampFields } from '../types'
import { InputSection, JSONValueType, NotificationSection, TextSection } from '../session/types'
import { QuestionOption } from '../training/types'

export type QuestionnaireBooklet = 1 | 2 | 3 | 4

export enum QuestionnaireUser {
  'cadet' = 'cadet',
  'teacher' = 'teacher',
  'parent' = 'parent',
  'facilitator' = 'facilitator',
}

export enum QuestionnaireType {
  'planning' = 'planning',
  'spence_ssq' = 'spence-ssq',
  'erssq' = 'erssq',
  'erssq2' = 'erssq2',
  'james_anxiety' = 'james-anxiety',
  'dylan_anger' = 'dylan-anger',
  'feedback' = 'feedback',
  'observational_code' = 'observational-code',
  'training_pre' = 'training-pre',
  'training_post' = 'training-post',
  'custom' = 'custom',
}

export type QuestionnaireKey =
  | `${QuestionnaireType.planning}-${QuestionnaireUser.teacher}`
  | `${QuestionnaireType.spence_ssq}-${QuestionnaireUser.parent}`
  | `${QuestionnaireType.spence_ssq}-${QuestionnaireUser.teacher}`
  | `${QuestionnaireType.erssq}-${QuestionnaireUser.parent}`
  | `${QuestionnaireType.erssq}-${QuestionnaireUser.teacher}`
  | `${QuestionnaireType.erssq2}-${QuestionnaireUser.parent}`
  | `${QuestionnaireType.erssq2}-${QuestionnaireUser.teacher}`
  | `${QuestionnaireType.james_anxiety}`
  | `${QuestionnaireType.dylan_anger}`
  | `${QuestionnaireType.feedback}-${QuestionnaireUser.parent}`
  | `${QuestionnaireType.feedback}-${QuestionnaireUser.teacher}`
  | `${QuestionnaireType.feedback}-${QuestionnaireUser.cadet}`
  | `${QuestionnaireType.observational_code}`
  | `${QuestionnaireType.training_pre}`
  | `${QuestionnaireType.training_post}`
  | `${QuestionnaireType.custom}`

export const questionnaireKeyLookup: {
  [key in QuestionnaireKey]: { type: QuestionnaireType; userType?: QuestionnaireUser }
} = {
  'planning-teacher': { type: QuestionnaireType.planning, userType: QuestionnaireUser.teacher },
  'spence-ssq-parent': { type: QuestionnaireType.spence_ssq, userType: QuestionnaireUser.parent },
  'spence-ssq-teacher': { type: QuestionnaireType.spence_ssq, userType: QuestionnaireUser.teacher },
  'erssq-parent': { type: QuestionnaireType.erssq, userType: QuestionnaireUser.parent },
  'erssq-teacher': { type: QuestionnaireType.erssq, userType: QuestionnaireUser.teacher },
  'erssq2-parent': { type: QuestionnaireType.erssq2, userType: QuestionnaireUser.parent },
  'erssq2-teacher': { type: QuestionnaireType.erssq2, userType: QuestionnaireUser.teacher },
  'james-anxiety': { type: QuestionnaireType.james_anxiety, userType: QuestionnaireUser.cadet },
  'dylan-anger': { type: QuestionnaireType.dylan_anger, userType: QuestionnaireUser.cadet },
  'feedback-parent': { type: QuestionnaireType.feedback, userType: QuestionnaireUser.parent },
  'feedback-teacher': { type: QuestionnaireType.feedback, userType: QuestionnaireUser.teacher },
  'feedback-cadet': { type: QuestionnaireType.feedback, userType: QuestionnaireUser.cadet },
  'training-pre': { type: QuestionnaireType.training_pre, userType: QuestionnaireUser.facilitator },
  'training-post': { type: QuestionnaireType.training_post, userType: QuestionnaireUser.facilitator },
  'observational-code': { type: QuestionnaireType.observational_code },
  custom: { type: QuestionnaireType.custom },
}

export const questionnaireKeyLabels: { [key in QuestionnaireKey]: string } = {
  'planning-teacher': 'Program Planning (Teacher)',
  'spence-ssq-parent': 'SSQ (Parent)',
  'spence-ssq-teacher': 'SSQ (Teacher)',
  'erssq-parent': 'ERSSQ (Parent)',
  'erssq-teacher': 'ERSSQ (Teacher)',
  'erssq2-parent': 'ERSSQ (Parent)',
  'erssq2-teacher': 'ERSSQ (Teacher)',
  'james-anxiety': 'James Anxiety',
  'dylan-anger': 'Dylan Anger',
  'feedback-parent': 'Program Satisfaction (Parent)',
  'feedback-teacher': 'Program Satisfaction (Teacher)',
  'feedback-cadet': 'Program Satisfaction (Cadet)',
  'observational-code': 'Observational Tool',
  'training-pre': 'Training (Pre)',
  'training-post': 'Training (Post)',
  custom: 'Custom',
}

export const getQuestionnaireKey = (type: QuestionnaireType, userType?: QuestionnaireUser): QuestionnaireKey => {
  const keys = Object.keys(questionnaireKeyLookup) as QuestionnaireKey[]
  for (const key of keys) {
    if (
      questionnaireKeyLookup[key].type === type &&
      (!('userType' in questionnaireKeyLookup[key]) || questionnaireKeyLookup[key].userType === userType)
    )
      return key
  }
  throw new Error(`Questionnaire key not found for type: ${type} and userType: ${userType}`)
}

export const questionnaireTypeTotalScoreMap: {
  [key in Extract<QuestionnaireKey, 'james-anxiety' | 'dylan-anger'>]: number
} = {
  'james-anxiety': 20,
  'dylan-anger': 20,
}

export const scorelessQuestionnaireTypes: QuestionnaireType[] = [QuestionnaireType.feedback]

export interface QuestionnaireSummary {
  mentor_id?: number | null
  cadet_mentor_id?: number | null
  drupal_user_id?: number | null
  provider_uid?: string
  name: string
  cadet_name?: string
  facilitator_names?: string
  questionnaire_type: QuestionnaireKey
  questionnaire_booklet?: QuestionnaireBooklet
  manual_score?: number
  observational_code_score?: number
  score?: number
  date?: string
  facilitator_created: boolean
  responses: QuestionnaireResponse[]
}

export interface QuestionnaireSummarySaved extends QuestionnaireSummary, TimestampFields {
  id: number
  total: number | null
}

export interface QuestionnaireResponse {
  unique_key: string
  value: JSONValueType
  score?: number | null
  total?: number
  non_scoring?: boolean
}

export type QuestionnaireUserDataStatus =
  | false
  | 'loading'
  | 'submitted'
  | 'empty'
  | 'ready'
  | 'submitting'
  | 'submission_error'

export interface QuestionnaireStateHookObject {
  state: QuestionnaireSummary
  userDataStatus: QuestionnaireUserDataStatus
  setUserDataStatus: React.Dispatch<React.SetStateAction<this['userDataStatus']>>
  questionnaireData: false | Questionnaire
  setQuestionnaireData: (data: this['questionnaireData']) => void
  dispatch: (action: ActionTypes) => void
  previewing: boolean
  setPreviewing: React.Dispatch<React.SetStateAction<this['previewing']>>
  previewState: PreviewState
  setPreviewState: React.Dispatch<React.SetStateAction<this['previewState']>>
  sessionSectionId: number
  setSessionSectionId: React.Dispatch<React.SetStateAction<this['sessionSectionId']>>
}

export interface PreviewState {
  currentIndex: number
}

export interface Questionnaire extends IdField, UidField {
  title: string
  public_title?: string
  short_title?: string
  booklet?: `${QuestionnaireBooklet}`
  type: QuestionnaireType
  user_type: QuestionnaireUser
  panels: PanelSection[]
  order?: number
}

export const questionnaireBookletLabels: {
  [key in Exclude<QuestionnaireSummary['questionnaire_booklet'], undefined>]: string
} = {
  1: 'Pre-program',
  2: 'Post-program',
  3: 'Follow-up 1',
  4: 'Follow-up 2',
}

/** CakePHP Model: PanelSections */
export interface PanelSection extends Section, Exclude<SectionContainer, 'panel_sections'> {}

/** CakePHP Model: QuestionSections */
export interface QuestionSection extends Section, IdField, UidField, OrderField {
  title: string
  text: string
  type: 'choice' | 'score' | 'polar' | 'observational'
  score_range: number
  start_from?: number
  terminology: string
  question_options: QuestionnaireQuestionOption[]
  required: boolean
}

export interface QuestionnaireQuestionOption
  extends Omit<QuestionOption, 'media' | 'video_url' | 'media_type' | 'media_ratio' | 'correct'> {
  reverse_scored: boolean
  optionLabels?: [string, string, string]
}

/**
 * Section content types
 */

export const sectionProperties = [
  'text_sections',
  'input_sections',
  'question_sections',
  'notification_sections',
] as const

export type SectionProperty = (typeof sectionProperties)[number]

export interface SectionObject {
  property: SectionProperty
  section: SectionContainer[this['property']][0]
  index: number
}

export interface SectionPropsBase extends SectionObject {}

type SectionContainerShape = { [key in SectionProperty]: Section[] }
export interface SectionContainer extends SectionContainerShape {
  text_sections: TextSection[]
  input_sections: InputSection[]
  question_sections: QuestionSection[]
  notification_sections: NotificationSection[]
}

export type ContentSection = TextSection | InputSection | QuestionSection | NotificationSection

export interface Section extends IdField, OwnerOccupierFields, OrderField, TitleField {}
