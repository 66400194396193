import React, { useState, Fragment, useEffect, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import styled, { ThemeProvider } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { purple } from 'themes'
import { fontLight, fontRegular } from 'fonts'

import { END_SLIDE, HOLDING_SLIDE, TodoListSectionState } from 'shared/session/types'
import { CadetProgressLookupEntity } from 'shared/dashboard/types'
import { InputContext, useInputContext, useSavedInputValueObjects } from './InputContext'

import { Button, H1, H2, P, Row, Spacer, Column, SmallCaps, Hr } from 'common/ui'
import { Accordion, AccordionInner, AccordionTitle, AccordionRight, AccordionBody } from './common/Accordion'
import { CircleButton } from 'session/common/CircleButton'
import { InputComponent } from './common/InputComponent'
import { InlineCheckboxGroup } from 'common/CheckboxGroup'
import { Checkbox } from 'common/Checkbox'
import { SessionDetails } from './SessionDetail'
import { PdfModal, PdfStatus } from 'common/PdfModal'
import { FacilitatorChecklistPrintPayload } from './printable/FacilitatorChecklistPrint'
import { InfoTooltip } from 'common/Tooltip'
import { SpinnerWithLabel } from 'common/Spinner'
import { LinkButton } from 'common/LinkButton'

import { createPdf, getPastSharedSessionInputValues } from 'api'
import { getSlides, getStepByUid } from './reducers'
import { postJson, toJson } from 'utils/apiUtils'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { useUserState } from 'app/UserState'
import { useSessionState } from './SessionState'
import { useFacilitatorState } from './hooks/useProfileState'
import { useApiEndpoint, useEndpoint } from 'dashboards/utils/endpointHooks'
import { QuestionnaireModuleCode, questionnaireModuleCodes } from 'shared/dashboard/moduleCodes'
import { MobileMessageModal } from 'app/MobileMessageModal'
import { sortByKey } from 'utils/sortUtils'
import { facilitatorDashboardBaseUrl } from 'dashboards/facilitator/constants'

interface Props {
  type: typeof END_SLIDE | typeof HOLDING_SLIDE
  readOnly?: boolean
}

const textStates = {
  notes: {
    default: 'Send notes',
    processing: 'Processing...',
    success: 'Send Again',
    nothingToDo: 'No notes to send',
    failed: 'Failed to send - try again',
    tooltip: `Pressing this button will automatically email you the meeting notes. Once the module is closed, the notes will no longer be available. Please download and save to your own files.`,
  },
  tipsheet: {
    default: 'Send tip sheet',
    processing: 'Sending...',
    success: 'Send Tipsheet Again',
    failed: 'Failed to send - try again',
    tooltip: `Select this button to automatically email all Mentors with the 'Teacher Tip Sheets' and/or 'Community Activity Tip Sheets' permission. If you need to edit an email address, please edit in your group settings before closing this live meeting.`,
  },
  questionnaire: {
    default: `Send Q're Booklet`,
    processing: 'Sending...',
    success: 'Send Questionnaire Again',
    failed: 'Failed to send - try again',
    tooltip: (
      <span>
        Pressing this button will email all mentors with a 'Questionnaires' permission. If you need to edit an email
        address please edit in your group settings before closing this live meeting.
        <br />
        <br />A mentor can complete an automatically linked questionnaire once, they will not be able to repeat it
        through the Mentor Portal. Please use "GET LINK &amp; SHARE" OR "PRINT &amp; WRITE" methods if a repeat is
        required.
      </span>
    ),
  },
  facilitatorChecklist: {
    default: 'Download Checklist',
    processing: 'Processing...',
    success: 'Download Checklist Again',
    failed: 'Failed to generate - try again',
    tooltip: `Please download and save to your own files.`,
  },
  mentorChecklist: {
    default: 'Send To Do List',
    processing: 'Sending...',
    success: 'Send To Do List Again',
    failed: 'Failed to send - try again',
    tooltip: `Select this button to automatically email all Mentors with permissions for either the ‘Parent Group Meetings & Reminders’ or ‘Parent Meeting Notes’ permission. If you need to edit an email address, please exit the meeting and edit in your group settings before returning to close this live meeting.`,
  },
} as const satisfies {
  [key: string]: {
    default: string
    processing: string
    success: string
    failed: string
    nothingToDo?: string
    tooltip?: NonNullable<ReactNode>
  }
}

type ButtonText<K extends keyof typeof textStates> = (typeof textStates)[K][keyof (typeof textStates)[K]]

const handleEmailStatusUpdate = <K extends keyof typeof textStates>(
  res: { success: boolean; sent?: string[]; failed?: string[] },
  key: K
): (typeof textStates)[K]['success'] | (typeof textStates)[K]['failed'] => {
  if (!res.success || ((res.failed || []).length > 0 && (res.sent || []).length === 0)) return textStates[key].failed
  if ((res.failed || []).length > 0) alert(`Failed to send to: ${res.failed!.join(', ')}`)
  return textStates[key].success
}

export const FacilitatorChecklistOverview: React.FC<Props> = ({ type, readOnly }) => {
  const history = useHistory()
  const inputContext = useInputContext()
  const facilitatorState = useFacilitatorState()
  const { sessionData, setSessionData, state, isAssistant } = useSessionState()
  const { accessToken } = useUserState()

  const slides = getSlides(sessionData, state, [])
  const isStartSlide = type === HOLDING_SLIDE

  const [confirmMessage, setConfirmMessage] = useState<string | null>(null)
  const [showConfirmMessage, setShowConfirmMessage] = useState<boolean>(false)
  const [expandedIds, setExpandedIds] = useState<string[]>([])
  const [pdfStatus, setPdfStatus] = useState<PdfStatus>('ready')
  const [pdfUrl, setPdfUrl] = useState('')
  const [notesEmailStatus, setNotesEmailStatus] = useState<ButtonText<'notes'>>(textStates.notes.default)
  const [tipsheetStatus, setTipsheetStatus] = useState<ButtonText<'tipsheet'>>(textStates.tipsheet.default)
  const [questionnaireStatus, setQuestionnaireStatus] = useState<ButtonText<'questionnaire'>>(
    textStates.questionnaire.default
  )
  const [mentorChecklistEmailStatus, setMentorChecklistEmailStatus] = useState<ButtonText<'mentorChecklist'>>(
    textStates.mentorChecklist.default
  )

  const [savedSession, setSavedSession] = useState<boolean>(false)
  const [savingSession, setSavingSession] = useState<boolean>(false)
  const [errorSavingSession, setErrorSavingSession] = useState<boolean | string>(false)
  const [selectedAttendees, setSelectedAttendees] = useState<string[]>([])
  const [completedAttendees, setCompletedAttendees] = useState<string[]>([])
  const [
    possibleAttendees,
    { loaded: loadedPossibleAttendees, loading: loadingPossibleAttendees, fetch: refetchPossibleAttendees },
  ] = useEndpoint<CadetProgressLookupEntity[]>(
    isStartSlide || !sessionData || isAssistant ? null : `/api/v1/sessions/${sessionData.uid}/potential_attendees`
  )

  // load all skipped slides for this session
  const [skippedSlides, { loaded: skippedSlidesLoaded }] = useApiEndpoint<
    typeof getPastSharedSessionInputValues<boolean>
  >(
    getPastSharedSessionInputValues<boolean>,
    !!sessionData,
    {
      session_uid: sessionData ? sessionData.uid : '_NEVER_',
      owner: 'skipped_slides',
      include_current_session: true,
    },
    accessToken
  )
  const skippedSlideUids = (skippedSlides || []).map(({ name }) => name)

  // load todo list state for this session in order to find out what slideUid it's on
  const [todoData, { loaded: todoDataLoaded }] = useApiEndpoint<
    typeof getPastSharedSessionInputValues<TodoListSectionState>
  >(
    getPastSharedSessionInputValues<TodoListSectionState>,
    !!sessionData,
    {
      session_uid: sessionData ? sessionData.uid : '_NEVER_',
      name: 'todo_list_state',
      include_current_session: true,
    },
    accessToken
  )
  const todoState = (todoData || []).sort(sortByKey('updated_at', 'descending'))[0]?.value || null
  const todoSkipped = !!todoState && !!todoState.slideUid && skippedSlideUids.includes(todoState.slideUid)

  const checklistItems = useSavedInputValueObjects<boolean>({
    owner: 'Steps',
    owner_id: undefined,
    name: undefined,
    participant_uid: 'shared',
  })

  useEffect(() => {
    if (loadedPossibleAttendees && possibleAttendees) {
      const completedAttendees = possibleAttendees.filter(({ completed }) => completed).map(attendeeToValue)
      const connectedUids = state.participants.filter((p) => p.status === 'connected').map((p) => p.profile.uid)
      ReactDOM.unstable_batchedUpdates(() => {
        setCompletedAttendees(completedAttendees)
        setSelectedAttendees([
          ...completedAttendees,
          ...possibleAttendees
            .filter((a) => !a.completed)
            .filter((a) => connectedUids.includes(a.uid))
            .map(attendeeToValue),
        ])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedPossibleAttendees])

  const handleSessionConcluded = () => {
    if (type !== 'END_SLIDE' || !possibleAttendees || !sessionData || sessionData.uid.match(/^DISCARD_/)) return
    if (!selectedAttendees.length) {
      setErrorSavingSession(`* Please indicate who has completed the module.`)
      return
    }
    const autoRedirectBackToDashboard = completedAttendees.length >= possibleAttendees.length
    ReactDOM.unstable_batchedUpdates(() => {
      setSavedSession(false)
      setSavingSession(true)
      setErrorSavingSession(false)
    })
    postJson(
      `/api/v1/sessions/${sessionData.uid}/conclude`,
      { attendees: possibleAttendees.filter((a) => selectedAttendees.includes(attendeeToValue(a))) },
      getAuthRequestParams(accessToken)
    )
      .then((response) => {
        console.log('✅ Session marked as concluded')
        ReactDOM.unstable_batchedUpdates(() => {
          setSavingSession(false)
          setErrorSavingSession(false)
          setSavedSession(true)
          setSessionData({ ...sessionData, concluded: true })
          refetchPossibleAttendees()
        })
        if (autoRedirectBackToDashboard) setTimeout(() => history.push(`${facilitatorDashboardBaseUrl}/meetings`))
      })
      .catch((e) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setSavingSession(false)
          setErrorSavingSession(e.message || true)
        })
        // TODO: SENTRY
        console.log('❌ Failed to mark session as concluded')
      })
  }

  const handleDownload = () => {
    const templateUrl = `${window.location.origin}/print/session/checklist`
    const payload: FacilitatorChecklistPrintPayload = {
      module: sessionData ? sessionData.module.title : '',
      date: new Date().toLocaleDateString(),
      sections: [],
    }
    slides.forEach((slide) => {
      if (slide.type !== 'MODULE_SLIDE' || !sessionData) return
      const currentStep = getStepByUid(sessionData, slide.uid)
      if (!currentStep) return
      payload.sections.push({
        title: currentStep.title || '',
        checklist: (currentStep.facilitator_steps || []).map((checklistItem, idx) => {
          const inputValue = checklistItems.find(
            ({ name, owner_id }) =>
              name === `Facilitator_${sessionData.group_id}_ChecklistItem_${idx}` && owner_id === currentStep.id
          )
          return { label: checklistItem, value: inputValue ? inputValue.value : false }
        }),
      })
    })
    setPdfStatus('busy')
    createPdf<FacilitatorChecklistPrintPayload>(templateUrl, payload, accessToken)
      .then((pdf) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setPdfUrl(pdf.url)
          setPdfStatus('success')
        })
      })
      .catch(() => setPdfStatus('error'))
  }

  const handleNotesExport = () => {
    const facilitatorNotes = JSON.parse(
      localStorage.getItem(`facilitatorNotes-${sessionData ? sessionData.uid : ''}`) || ''
    )
    if (sessionData && facilitatorNotes && facilitatorNotes.length > 1) {
      setNotesEmailStatus(textStates.notes.processing)
      const formData = new FormData()
      formData.append('notes', facilitatorNotes)
      fetch(`/api/v1/sessions/${sessionData.uid}/session_notes`, {
        method: 'POST',
        body: formData,
        ...getAuthRequestParams(accessToken),
      })
        .then(toJson)
        .then((response: { success: boolean }) => {
          setNotesEmailStatus(response.success ? textStates.notes.success : textStates.notes.failed)
          console.log('Successfully Sent Email')
        })
        .catch((e) => {
          console.warn(e)
          console.log('Email Failed to Send')
          setNotesEmailStatus(textStates.notes.failed)
        })
    } else {
      setNotesEmailStatus(textStates.notes.nothingToDo)
    }
  }

  const handleTipsheetNotification = () => {
    if (!sessionData) return
    setTipsheetStatus(textStates.tipsheet.processing)
    postJson(
      `/api/v1/sessions/${sessionData.uid}/email_tipsheets`,
      { participant_uids: state.participants.map((participant) => participant.profile.uid) },
      getAuthRequestParams(accessToken),
      { retries: 0 }
    )
      .then((res) => setTipsheetStatus(handleEmailStatusUpdate(res, 'tipsheet')))
      .catch((e) => setTipsheetStatus(textStates.tipsheet.failed))
  }

  const handleQuestionnaireNotification = () => {
    if (!sessionData) return
    setQuestionnaireStatus(textStates.questionnaire.processing)
    postJson(`/api/v1/sessions/${sessionData.uid}/email_questionnaires`, {}, getAuthRequestParams(accessToken), {
      retries: 0,
    })
      .then((res) => setQuestionnaireStatus(handleEmailStatusUpdate(res, 'questionnaire')))
      .catch((e) => setQuestionnaireStatus(textStates.questionnaire.failed))
  }

  const handleMentorChecklistEmail = () => {
    if (!sessionData) return
    setMentorChecklistEmailStatus(textStates.mentorChecklist.processing)
    postJson(`/api/v1/sessions/${sessionData.uid}/email_mentor_checklist`, {}, getAuthRequestParams(accessToken), {
      retries: 0,
    })
      .then((res) => setMentorChecklistEmailStatus(handleEmailStatusUpdate(res, 'mentorChecklist')))
      .catch((e) => setMentorChecklistEmailStatus(textStates.mentorChecklist.failed))
  }

  const handleConfirm = () => setShowConfirmMessage(false)

  const handleNotify = (message: string) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setShowConfirmMessage(true)
      setConfirmMessage(message)
    })
  }

  if (!facilitatorState) return null

  let counter: number = 0

  return (
    <ThemeProvider theme={purple}>
      <Container>
        <H1>{isStartSlide ? 'Meeting Preparation' : 'End Meeting'}</H1>
        <Banner src={require('./assets/prep-end.png')} alt="" />
        {isStartSlide && (
          <>
            <SessionDetails />
            <H2>Meeting Contents:</H2>
            <AccordionWrapper>
              {slides.map((slide, idx) => {
                if (slide.type !== 'MODULE_SLIDE' || !sessionData) return null
                const currentStep = getStepByUid(sessionData, slide.uid)
                if (!currentStep) return null

                const expanded = expandedIds.find((a) => a === slide.uid)
                return (
                  <Accordion key={idx}>
                    <AccordionInner>
                      <AccordionTitle>{currentStep.title}</AccordionTitle>
                      <AccordionRight
                        onClick={() =>
                          setExpandedIds(
                            expanded ? expandedIds.filter((id) => id !== slide.uid) : [...expandedIds, slide.uid]
                          )
                        }>
                        <span>{expanded ? 'HIDE' : 'SHOW'}</span>
                        <Spacer inline />
                        <CircleButton size="s" children={expanded ? '▲' : '▼'} />
                      </AccordionRight>
                    </AccordionInner>
                    <AccordionBody expanded={!!expanded}>
                      {!currentStep.facilitator_steps ? (
                        <P>This slide doesn't have a facilitator checklist</P>
                      ) : (
                        <Checklist>
                          {currentStep.facilitator_steps.map((checklistItem, idx) => {
                            const counterValue = counter + 1
                            counter++
                            return <P key={idx}>{counterValue.toString() + ': ' + checklistItem}</P>
                          })}
                        </Checklist>
                      )}
                    </AccordionBody>
                    <hr />
                  </Accordion>
                )
              })}
            </AccordionWrapper>
            {!readOnly && (
              <Button
                size="s"
                onClick={handleDownload}
                children={pdfStatus === 'busy' ? 'Processing...' : 'Download Checklist'}
                disabled={pdfStatus === 'busy'}
              />
            )}
          </>
        )}
        {!isStartSlide && (
          <>
            {sessionData && (
              <>
                <H2>Summary</H2>
                <AccordionWrapper>
                  {slides.map((slide, idx) => {
                    if (slide.type !== 'MODULE_SLIDE' || !sessionData) return null
                    const currentStep = getStepByUid(sessionData, slide.uid)
                    if (!currentStep) return null
                    return (
                      <Fragment key={idx}>
                        <SmallCaps style={{ display: 'block', color: 'white', fontWeight: 600, marginTop: 15 }}>
                          {currentStep.title}
                        </SmallCaps>
                        {!currentStep.facilitator_steps ? (
                          <P style={{ opacity: 0.7 }}>This slide doesn't have a facilitator checklist</P>
                        ) : (
                          <Checklist>
                            {currentStep.facilitator_steps
                              .filter((_) => _)
                              .map((checklistItem, i) => {
                                const counterValue = counter + 1
                                counter++
                                return (
                                  <InputContext.Provider
                                    key={i}
                                    value={{
                                      ...inputContext,
                                      owner: 'Steps',
                                      owner_id: currentStep.id,
                                      participant_uid: 'shared',
                                    }}>
                                    <InputComponent<boolean>
                                      name={`Facilitator_${sessionData.group_id}_ChecklistItem_${i}`}
                                      defaultValue={false}>
                                      {({ value, onChange }) => (
                                        <Checkbox
                                          size="s"
                                          checked={value || false}
                                          onChange={onChange}
                                          wrapperProps={{ style: { marginBottom: 8 } }}
                                          labelProps={{ style: { fontWeight: 400 } }}
                                          children={counterValue.toString() + ': ' + checklistItem}
                                          disabled={readOnly}
                                        />
                                      )}
                                    </InputComponent>
                                  </InputContext.Provider>
                                )
                              })}
                          </Checklist>
                        )}
                        <hr />
                      </Fragment>
                    )
                  })}
                </AccordionWrapper>
                {!readOnly && (
                  <Row alignItems="center">
                    <Button
                      size="s"
                      onClick={handleDownload}
                      children={
                        pdfUrl || pdfStatus === 'success'
                          ? textStates.facilitatorChecklist.success
                          : pdfStatus === 'busy'
                            ? textStates.facilitatorChecklist.processing
                            : pdfStatus === 'error'
                              ? textStates.facilitatorChecklist.failed
                              : textStates.facilitatorChecklist.default
                      }
                      disabled={pdfStatus === 'busy'}
                    />
                    <InfoTooltip
                      theme="light"
                      tooltipStyle={{ background: '#fff', color: '#011A46' }}
                      placement="right"
                      content={textStates.facilitatorChecklist.tooltip}
                    />

                    <Spacer size="m" />

                    <Button
                      size="s"
                      children={notesEmailStatus}
                      disabled={notesEmailStatus === textStates.notes.processing}
                      onClick={handleNotesExport}
                    />
                    <InfoTooltip
                      theme="light"
                      tooltipStyle={{ background: '#fff', color: '#011A46' }}
                      placement="top"
                      content={textStates.notes.tooltip}
                    />
                  </Row>
                )}
              </>
            )}
            <Spacer size="m" />
            {!readOnly && !isAssistant && (
              <>
                <H2>
                  * Completed Module{' '}
                  <InfoTooltip
                    theme="light"
                    placement="top"
                    tooltipStyle={{ background: '#fff', color: '#011A46', fontFamily: 'sans-serif' }}
                    content={`Indicate ${
                      sessionData && sessionData.type === 'cadet' ? 'which cadets' : 'who'
                    } completed this meeting. If ${
                      sessionData && sessionData.type === 'cadet' ? 'any cadets' : 'anyone'
                    } left early or did not attend and you wish to run a catch-up meeting with them, please leave them unselected.`}
                  />
                </H2>
                {loadingPossibleAttendees || !possibleAttendees ? (
                  <SpinnerWithLabel label={`Loading potential attendees...`} labelStyle={{ color: '#fff' }} />
                ) : (
                  <InlineCheckboxGroup
                    size="s"
                    name="attendees"
                    value={selectedAttendees}
                    options={[
                      ...possibleAttendees.map((a) => ({
                        value: attendeeToValue(a),
                        label: a.completed ? (
                          <>
                            {a.displayName}
                            <br />
                            <small>(Previously completed)</small>
                          </>
                        ) : (
                          a.displayName
                        ),
                        disabled: a.completed,
                      })),
                      {
                        value: !selectedAttendees.includes('_EMPTY_') ? '_TRIGGER_CLEAR_' : '_EMPTY_',
                        label: `No ${sessionData ? sessionData.module.type : 'one'} completed`,
                        disabled: completedAttendees.length > 0,
                      },
                    ]}
                    onChange={(values) =>
                      setSelectedAttendees(
                        values.includes('_TRIGGER_CLEAR_') ? ['_EMPTY_'] : values.filter((v) => v !== '_EMPTY_')
                      )
                    }
                  />
                )}
                <Row alignItems="center">
                  <Column>
                    <Button
                      size="m"
                      children={
                        savedSession
                          ? `${sessionData && sessionData.catch_up_active ? 'Catch-up' : 'Live'} Meeting Closed`
                          : `Close ${sessionData && sessionData.catch_up_active ? 'Catch-up' : 'Live'} Meeting`
                      }
                      onClick={() => handleSessionConcluded()}
                      disabled={savedSession || savingSession || loadingPossibleAttendees}
                    />
                  </Column>
                  <Spacer size="m" flex="none" />
                  {savedSession && (
                    <>
                      <Column flex="1 1 auto">
                        <SmallCaps style={{ color: '#fff' }}>Saved!</SmallCaps>
                      </Column>
                      <Column>
                        <LinkButton
                          size="m"
                          to={`${facilitatorDashboardBaseUrl}/meetings`}
                          children="Return to Dashboard"
                        />
                      </Column>
                    </>
                  )}
                </Row>
                {errorSavingSession && (
                  <P
                    style={{ color: '#FF8686' }}
                    children={
                      errorSavingSession === true
                        ? `An error occurred while trying to save attendants, please try again.`
                        : errorSavingSession
                    }
                  />
                )}
                <Spacer size="m" />
                <Row alignItems="center">
                  {sessionData && sessionData.module.type === 'cadet' && !sessionData.catch_up_active && (
                    <>
                      <Button
                        size="s"
                        children={tipsheetStatus}
                        disabled={tipsheetStatus === textStates.tipsheet.processing}
                        onClick={() =>
                          savedSession
                            ? handleTipsheetNotification()
                            : handleNotify(
                                `You need to close the live meeting before you can send this Teacher Tip Sheet notification.`
                              )
                        }
                      />
                      <InfoTooltip
                        theme="light"
                        tooltipStyle={{ background: '#fff', color: '#011A46' }}
                        placement="top"
                        content={textStates.tipsheet.tooltip}
                      />
                      <Spacer size="m" />
                    </>
                  )}

                  {sessionData && sessionData.module.type !== 'cadet' && (
                    <>
                      <Button
                        size="s"
                        children={mentorChecklistEmailStatus}
                        disabled={
                          !skippedSlidesLoaded ||
                          !todoDataLoaded ||
                          todoSkipped ||
                          mentorChecklistEmailStatus === textStates.mentorChecklist.processing
                        }
                        onClick={handleMentorChecklistEmail}
                      />
                      <InfoTooltip
                        theme="light"
                        tooltipStyle={{ background: '#fff', color: '#011A46' }}
                        placement="top"
                        content={textStates.mentorChecklist.tooltip}
                      />
                      <Spacer size="m" />
                    </>
                  )}

                  {sessionData &&
                    sessionData.module.type === 'parent' &&
                    !sessionData.catch_up_active &&
                    questionnaireModuleCodes.includes(sessionData.module.module_code as QuestionnaireModuleCode) && (
                      <>
                        <Button
                          size="s"
                          children={questionnaireStatus}
                          disabled={questionnaireStatus === textStates.questionnaire.processing}
                          onClick={() =>
                            savedSession
                              ? handleQuestionnaireNotification()
                              : handleNotify(
                                  `You need to close the live meeting before you can send this questionnaire notification.`
                                )
                          }
                        />
                        <InfoTooltip theme="light" placement="top" content={textStates.questionnaire.tooltip} />
                        <Spacer size="m" />
                      </>
                    )}
                </Row>
              </>
            )}
            {readOnly && (
              <Row>
                <LinkButton
                  size="m"
                  to={`${facilitatorDashboardBaseUrl}/meetings`}
                  children="Back to Facilitator Portal"
                />
              </Row>
            )}
          </>
        )}
      </Container>
      <PdfModal
        status={pdfStatus}
        url={pdfUrl}
        onClose={() => setPdfStatus('ready')}
        onCancel={() => setPdfStatus('ready')}
        text1="Generating PDF"
      />
      <MobileMessageModal panelStyle={{ maxWidth: 400 }} isOpen={showConfirmMessage} onRequestClose={handleConfirm}>
        <H2 style={{ color: '#925BED', fontSize: '1.2rem' }}>Alert</H2>
        <Spacer />
        <Hr />
        <P>{confirmMessage}</P>
        <Spacer />
        <Button children="close" onClick={handleConfirm} />
      </MobileMessageModal>
    </ThemeProvider>
  )
}

function attendeeToValue(attendee: CadetProgressLookupEntity): string {
  return `${attendee.uid}-${attendee.mentor_id}`
}

const Container = styled.div`
  background-color: #464b61;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 30px;

  ${AccordionTitle}, ${AccordionRight}, ${H1}, ${H2}, ${AccordionBody}, ${P}, span {
    color: #fff;
  }

  ${H1}, ${H2} {
    margin-bottom: 30px;
  }

  ${H2} {
    font-size: 1.1em;
    margin-bottom: 15px;
  }

  ${AccordionTitle} {
    ${fontRegular};
    font-size: 16px;
  }

  ${AccordionRight} {
    span {
      font-size: 14px;
    }
  }

  ${AccordionBody} {
    p,
    span {
      ${fontLight};
    }
  }

  hr {
    border: none;
    border-bottom: 1px solid #2f3343;
    box-shadow: 0px 1px 0px #6e7697;
  }
`

const Banner = styled.img`
  background: #fff;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 30px;
`

const AccordionWrapper = styled.div`
  margin-bottom: 30px;
`

const Checklist = styled.div`
  margin: 15px 0;
`
