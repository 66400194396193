import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import uniqBy from 'lodash/uniqBy'

import { Activity } from 'types'
import { ProfileProgress } from 'api'
import { State as SkillTrackerState } from 'skill-tracker/SkillTrackerState'
import { MentorEntity, SessionEntity, GroupEntity } from 'shared/dashboard/types'
import { AnyModuleCode, ParentModuleCode } from 'shared/dashboard/moduleCodes'

import { Table } from 'reporting/common/ui'
import { Column, Row, Spacer, Text } from 'common/ui'
import { Tooltip } from 'common/Tooltip'
import {
  gameModes,
  PreQuiz1,
  PostQuiz1,
  PreQuiz2,
  SecretAgentViewingPanel,
  SoundSignal,
  OperationO2Regulator,
  EnemyThoughtDestruction,
  RelaxationGadgetry,
  TheFriendshipForce,
  TheFriendshipForce2,
  DECODERProblemDemolition,
  TheUltimateChillOutChallenge,
  CrackingTheConversationCode,
  CooperationChallenge,
  MistakeMania,
  CooperationChallenge2,
  CooperationChallenge3,
  BullyGuardBodyArmour,
  FearFighter,
  Graduation,
} from 'activities'

import { getConfirmedModuleSkills, getSkillUsesForSkills } from 'skill-tracker/reducers'
import { locations } from 'skill-tracker/constants/locations'

import { byActivity, isJournalActivity } from 'utils/activityUtils'
import { intersperseSpacers } from 'utils/intersperseSpacers'
import { getGroupPublicName } from 'utils/titleUtils'
import { getModuleTitle } from 'dashboards/constant/moduleCodes'

interface AssessmentProgressSection {
  type: 'assessment'
  assessment: string
}

interface MeetingProgressSection {
  type: 'parent' | 'cadet'
  moduleCodes: AnyModuleCode[]
  alternateModuleCodes?: AnyModuleCode[]
}

interface ActivitiesProgressSection {
  type: 'activities'
  activityFrom: Activity
  activityTo: Activity
}

interface SkillTrackerProgressSection {
  type: 'skilltracker'
  moduleCodes: AnyModuleCode[]
}

interface TipsheetProgressSection {
  type: 'tipsheet'
  moduleCodes: AnyModuleCode[]
}

type ProgressSection =
  | AssessmentProgressSection
  | MeetingProgressSection
  | ActivitiesProgressSection
  | SkillTrackerProgressSection
  | TipsheetProgressSection

const parentInfoCodes: ParentModuleCode[] = ['parent-info-1', 'parent-info-2', 'parent-info-3', 'parent-info-4']

const progressSections: ProgressSection[] = [
  {
    type: 'assessment',
    assessment: '1',
  },
  {
    type: 'parent',
    moduleCodes: ['parent-intro', 'parent-info-1'],
  },
  {
    type: 'activities',
    activityFrom: PreQuiz1,
    activityTo: PostQuiz1,
  },
  {
    type: 'cadet',
    moduleCodes: ['1', '1a', '1b'],
  },
  {
    type: 'parent',
    moduleCodes: ['1', '1a', '1b'],
    alternateModuleCodes: parentInfoCodes,
  },
  {
    type: 'tipsheet',
    moduleCodes: ['1', '1a', '1b'],
  },
  {
    type: 'activities',
    activityFrom: PreQuiz2,
    activityTo: SoundSignal,
  },
  {
    type: 'skilltracker',
    moduleCodes: ['1', '1a', '1b'],
  },
  {
    type: 'cadet',
    moduleCodes: ['2', '2a', '2b'],
  },
  {
    type: 'parent',
    moduleCodes: ['2', '2a', '2b'],
    alternateModuleCodes: parentInfoCodes,
  },
  {
    type: 'tipsheet',
    moduleCodes: ['2', '2a', '2b'],
  },
  {
    type: 'activities',
    activityFrom: SecretAgentViewingPanel,
    activityTo: OperationO2Regulator,
  },
  {
    type: 'skilltracker',
    moduleCodes: ['2', '2a', '2b'],
  },
  {
    type: 'cadet',
    moduleCodes: ['3', '3a', '3b'],
  },
  {
    type: 'parent',
    moduleCodes: ['3', '3a', '3b', 'parent-info-2'],
  },
  {
    type: 'tipsheet',
    moduleCodes: ['3', '3a', '3b'],
  },
  {
    type: 'activities',
    activityFrom: EnemyThoughtDestruction,
    activityTo: RelaxationGadgetry,
  },
  {
    type: 'skilltracker',
    moduleCodes: ['3', '3a', '3b'],
  },
  {
    type: 'cadet',
    moduleCodes: ['4', '4a', '4b'],
  },
  {
    type: 'parent',
    moduleCodes: ['4', '4a', '4b'],
    alternateModuleCodes: parentInfoCodes,
  },
  {
    type: 'tipsheet',
    moduleCodes: ['4', '4a', '4b'],
  },
  {
    type: 'activities',
    activityFrom: TheFriendshipForce,
    activityTo: TheFriendshipForce2,
  },
  {
    type: 'skilltracker',
    moduleCodes: ['4', '4a', '4b'],
  },
  {
    type: 'cadet',
    moduleCodes: ['5', '5a', '5b'],
  },
  {
    type: 'parent',
    moduleCodes: ['5', '5a', '5b'],
    alternateModuleCodes: parentInfoCodes,
  },
  {
    type: 'tipsheet',
    moduleCodes: ['5', '5a', '5b'],
  },
  {
    type: 'activities',
    activityFrom: DECODERProblemDemolition,
    activityTo: TheUltimateChillOutChallenge,
  },
  {
    type: 'skilltracker',
    moduleCodes: ['5', '5a', '5b'],
  },
  {
    type: 'cadet',
    moduleCodes: ['6', '6a', '6b'],
  },
  {
    type: 'parent',
    moduleCodes: ['6', '6a', '6b', 'parent-info-3'],
  },
  {
    type: 'tipsheet',
    moduleCodes: ['6', '6a', '6b'],
  },
  {
    type: 'activities',
    activityFrom: CrackingTheConversationCode,
    activityTo: CooperationChallenge,
  },
  {
    type: 'skilltracker',
    moduleCodes: ['6', '6a', '6b'],
  },
  {
    type: 'cadet',
    moduleCodes: ['7', '7a', '7b'],
  },
  {
    type: 'parent',
    moduleCodes: ['7', '7a', '7b'],
    alternateModuleCodes: parentInfoCodes,
  },
  {
    type: 'tipsheet',
    moduleCodes: ['7', '7a', '7b'],
  },
  {
    type: 'activities',
    activityFrom: MistakeMania,
    activityTo: CooperationChallenge2,
  },
  {
    type: 'skilltracker',
    moduleCodes: ['7', '7a', '7b'],
  },
  {
    type: 'cadet',
    moduleCodes: ['8', '8a', '8b'],
  },
  {
    type: 'parent',
    moduleCodes: ['8', '8a', '8b'],
    alternateModuleCodes: parentInfoCodes,
  },
  {
    type: 'tipsheet',
    moduleCodes: ['8', '8a', '8b'],
  },
  {
    type: 'activities',
    activityFrom: CooperationChallenge3,
    activityTo: BullyGuardBodyArmour,
  },
  {
    type: 'skilltracker',
    moduleCodes: ['8', '8a', '8b'],
  },
  {
    type: 'cadet',
    moduleCodes: ['9', '9a', '9b'],
  },
  {
    type: 'parent',
    moduleCodes: ['9', '9a', '9b', 'parent-info-4'],
  },
  {
    type: 'tipsheet',
    moduleCodes: ['9', '9a', '9b'],
  },
  {
    type: 'assessment',
    assessment: '2',
  },
  {
    type: 'activities',
    activityFrom: FearFighter,
    activityTo: Graduation,
  },
  {
    type: 'skilltracker',
    moduleCodes: ['9', '9a', '9b'],
  },
  {
    type: 'cadet',
    moduleCodes: ['follow-up-1'],
  },
  {
    type: 'parent',
    moduleCodes: ['follow-up-1'],
  },
  {
    type: 'assessment',
    assessment: '3',
  },
  {
    type: 'cadet',
    moduleCodes: ['follow-up-2'],
  },
  {
    type: 'parent',
    moduleCodes: ['follow-up-2'],
  },
  {
    type: 'assessment',
    assessment: '4',
  },
]

const activities = gameModes['SAS-SG-D'].activities

interface Props {
  group: GroupEntity
  profileProgress: ProfileProgress
  sessions: SessionEntity[]
  externalSessions?: SessionEntity[]
  mentors: MentorEntity[]
}

export const CadetProgressTable: React.FC<Props> = ({
  group,
  profileProgress,
  sessions,
  externalSessions,
  mentors,
}) => (
  <Table striped>
    <tbody>
      {progressSections.map((section, i) => {
        switch (section.type) {
          case 'activities':
            return <ActivitiesSection key={i} section={section} progress={profileProgress} />
          case 'assessment':
            return null
          case 'cadet':
          case 'parent':
            return (
              <MeetingSection
                key={i}
                group={group}
                section={section}
                progress={profileProgress}
                sessions={sessions}
                externalSessions={externalSessions}
                mentors={mentors}
              />
            )
          case 'skilltracker':
            return <SkillTrackerSection key={i} section={section} progress={profileProgress} />
          case 'tipsheet':
            return (
              <>
                <TeacherTipsheetSection
                  key={`${i}-teacher`}
                  section={section}
                  progress={profileProgress}
                  sessions={sessions}
                  externalSessions={externalSessions}
                  tipsheetMentors={mentors.filter((mentor) => mentor.permission_tip_sheets)}
                />
                <CommunityTipsheetSection
                  key={`${i}-community`}
                  section={section}
                  progress={profileProgress}
                  sessions={sessions}
                  externalSessions={externalSessions}
                  tipsheetMentors={mentors.filter((mentor) => mentor.permission_community_tip_sheets)}
                />
              </>
            )
        }
        // eslint-disable-next-line no-unreachable
        return null
      })}
    </tbody>
  </Table>
)

const missionIcon = (
  <svg style={{ marginRight: 10 }} width="15" height="11" viewBox="0 0 15 11">
    <path
      fillRule="evenodd"
      d="M12.3811 0.859497H3.50824C2.80819 0.859497 2.24069 1.427 2.24069 2.12705V7.83102V7.83114H0.973145V9.73246C0.973145 10.4325 1.54065 11 2.24069 11H13.6486C14.3487 11 14.9162 10.4325 14.9162 9.73246V7.83114H13.6486V7.83102V2.12705C13.6486 1.427 13.0811 0.859497 12.3811 0.859497ZM3.50827 7.83105V2.12708H12.3811V7.83105H3.50827ZM2.24072 9.73253V9.09875H13.6487V9.73253H2.24072Z"
      fill="#011A46"
    />
  </svg>
)

const activityIcon = (
  <svg style={{ marginRight: 10 }} width="14" height="14" viewBox="0 0 14 14">
    <path
      fillRule="evenodd"
      d="M7.24451 1.77307L5.72574 3.29184L2.68845 2.85794L0.301863 5.24452L2.03746 6.98012L2.03741 6.98017C1.55811 7.45947 1.55811 8.23657 2.03741 8.71587L2.90527 9.58374L2.47138 10.0176L3.33923 10.8855L3.77312 10.4516L4.64096 11.3194C5.12023 11.7987 5.89728 11.7987 6.37659 11.3195L8.1122 13.0551L10.4988 10.6685L10.0649 7.63119L11.5838 6.11232C12.9533 4.74282 13.7909 2.24451 12.4516 0.905221C11.1123 -0.434063 8.61401 0.40357 7.24451 1.77307ZM4.6409 9.58381L5.50865 10.4516L9.8479 6.1123L9.84794 6.11258L10.7159 5.24461C11.6674 4.29312 12.2791 2.46858 11.5838 1.77321C10.8884 1.07784 9.06385 1.68957 8.11236 2.64106L7.2446 3.50882L7.24466 3.50882L2.90541 7.84807L2.90538 7.84804L2.90526 7.84816L3.77305 8.71596L6.37671 6.1123L7.24456 6.98015L4.6409 9.58381ZM3.12231 4.15992L4.64104 4.37688L2.90534 6.11258L2.03749 5.24473L3.12231 4.15992ZM7.24452 10.4515L8.98022 8.71582L9.19719 10.2346L8.11237 11.3194L7.24452 10.4515ZM8.11237 5.24466C7.63307 4.76536 7.63307 3.98826 8.11237 3.50896C8.59167 3.02966 9.36877 3.02966 9.84807 3.50896C10.3274 3.98826 10.3274 4.76536 9.84807 5.24466C9.36877 5.72396 8.59167 5.72396 8.11237 5.24466Z"
      fill="#011A46"
    />
  </svg>
)

const skillTrackerIcon = (
  <svg style={{ marginRight: 10 }} width="16" height="15" viewBox="0 0 16 15">
    <path
      fillRule="evenodd"
      d="M4.69814 9.87299L3.81879 15L8.42313 12.5794L13.0275 15L12.1481 9.87299L15.8731 6.24202L10.7253 5.494L8.42313 0.829285L6.12096 5.494L0.973145 6.24202L4.69814 9.87299ZM10.7749 9.42676L11.3301 12.6639L8.42302 11.1355L5.51592 12.6639L6.07113 9.42676L3.71924 7.13423L6.96947 6.66195L8.42302 3.71674L9.87657 6.66195L13.1268 7.13423L10.7749 9.42676Z"
      fill="#011A46"
    />
  </svg>
)

// const teacherTipSheetIcon = (
//   <svg style={{ marginLeft: 2, marginRight: 10 }} width="11" height="13" viewBox="0 0 11 13">
//     <path
//       fillRule="evenodd"
//       d="M1.11409 0H6.91526L10.0268 3.11153V11.1409C10.0268 11.7562 9.52799 12.255 8.9127 12.255H1.11409C0.498794 12.255 0 11.7562 0 11.1409V1.11409C0 0.498794 0.498794 0 1.11409 0ZM5.57056 1.11401H1.11401V11.1408H8.91262V4.45643H6.68464C6.06935 4.45643 5.57056 3.95764 5.57056 3.34235V1.11401ZM6.68464 1.34494V3.34235H8.68205L6.68464 1.34494ZM2.78516 8.91267V7.79858H6.12742V8.91267H2.78516ZM2.78516 5.57031V6.6844H7.2415V5.57031H2.78516Z"
//       fill="#011A46"
//     />
//   </svg>
// )

const graduationIcon = (
  <svg style={{ marginLeft: 2, marginRight: 10 }} width="13" height="11" viewBox="0 0 13 11">
    <path
      fillRule="evenodd"
      d="M6.72214 9.77778V9.12335C7.93417 8.94998 8.9823 8.26855 9.64389 7.30173C11.3027 7.07334 12.2223 5.63653 12.2223 3.66688V1.83355C12.2223 1.15854 11.6751 0.611328 11.0001 0.611328H10.2254C10.0141 0.245885 9.61907 0 9.16658 0H3.05547C2.60299 0 2.20794 0.245885 1.99664 0.611328H1.22222C0.547207 0.611328 0 1.15854 0 1.83355V3.66688C0 5.63643 0.919497 7.07318 2.57814 7.30169C3.23973 8.26854 4.28788 8.94998 5.49992 9.12335V9.77778H4.88881C4.21379 9.77778 3.66659 10.325 3.66659 11H8.55547C8.55547 10.325 8.00827 9.77778 7.33325 9.77778H6.72214ZM1.22222 1.83355H1.83325V4.88889C1.83325 5.16869 1.86011 5.44222 1.9114 5.70703C1.46071 5.30134 1.22222 4.60262 1.22222 3.66688V1.83355ZM10.3888 1.83355V4.88889C10.3888 5.16878 10.3619 5.4424 10.3106 5.70729C10.7615 5.30164 11.0001 4.60283 11.0001 3.66688V1.83355H10.3888ZM3.05566 4.88883V1.22217H9.16677V4.88883C9.16677 6.57637 7.79876 7.94439 6.11122 7.94439C4.42368 7.94439 3.05566 6.57637 3.05566 4.88883Z"
      fill="#011A46"
    />
  </svg>
)

const ActivitiesSection: React.FC<{ section: ActivitiesProgressSection; progress: ProfileProgress }> = ({
  section,
  progress,
}) => {
  const from = activities.findIndex((a) => a.id === section.activityFrom.id)
  const to = activities.findIndex((a) => a.id === section.activityTo.id)
  const all = activities.slice(from, to + 1)
  return (
    <>
      {all.map((activity, i) => {
        const numPlays = progress.activity_attempts.filter(byActivity(activity)).length
        const text = isJournalActivity(activity)
          ? numPlays > 0
            ? 'Complete'
            : '-'
          : numPlays > 0
            ? `${numPlays} ${numPlays === 1 ? 'Play' : 'Plays'}`
            : '-'
        return (
          <tr key={i}>
            <td style={{ width: '50%', fontStyle: isJournalActivity(activity) ? 'italic' : undefined }}>
              {activity.id === 'graduation' ? graduationIcon : isJournalActivity(activity) ? activityIcon : missionIcon}
              {isJournalActivity(activity) ? `Mission: ${activity.title}` : activity.title}
            </td>
            <td>
              {text.length > 1 ? (
                <a
                  href={`${process.env.REACT_APP_CADET_URL}/report/SAS-SG-D/profile/${progress.id}/level${activity.level}#${activity.id}`}>
                  {text}
                </a>
              ) : (
                text
              )}
            </td>
          </tr>
        )
      })}
    </>
  )
}

const baseStyles: CSSProperties = {
  color: '#fff',
  textTransform: 'uppercase',
}

const backgroundStyles: { [key: string]: CSSProperties } = {
  cadet: {
    ...baseStyles,
    background: 'linear-gradient(180deg, #4CC3FB 0%, #18A0FB 100%)',
  },
  parent: {
    ...baseStyles,
    background: 'linear-gradient(180deg, #64CE57 0%, #0E9824 100%)',
  },
  teacher: {
    ...baseStyles,
    background: 'linear-gradient(180deg, #FAA634 0%, #FF8A00 100%)',
  },
  community: {
    ...baseStyles,
    background: 'linear-gradient(180deg, #ff68f0 0%, #fe5be7 100%)',
  },
}

const MeetingSection: React.FC<{
  group: GroupEntity
  section: MeetingProgressSection
  progress: ProfileProgress
  sessions: SessionEntity[]
  externalSessions?: SessionEntity[]
  mentors: MentorEntity[]
}> = ({ group, section, progress, sessions, externalSessions, mentors }) => {
  const allSessions = externalSessions ? [...sessions, ...externalSessions] : sessions
  const filteredSessions = allSessions.filter(
    (session) => section.moduleCodes.includes(session.module_code) && session.type === section.type
  )
  const uniqueFilteredSessions = uniqBy(filteredSessions, (session) => `${session.type}-${session.module_code}`)

  const alternateSessions = allSessions.filter(
    (session) => section.alternateModuleCodes?.includes(session.module_code) && session.type === section.type
  )

  if (!uniqueFilteredSessions.length && alternateSessions.length) {
    return null
  } else if (!uniqueFilteredSessions.length) {
    return (
      <tr>
        <td style={backgroundStyles[section.type]}>
          <strong>
            {section.type === 'cadet' ? 'Child Meeting - ' : 'Parent Meeting - '}
            {getModuleTitle(section.moduleCodes[0])}
          </strong>
        </td>
        <td style={backgroundStyles[section.type]}>-</td>
      </tr>
    )
  }

  const mentorIds = mentors.map((m) => m.id)
  const eventSubcategories = section.moduleCodes.map((moduleCode) => `${section.type}-${moduleCode}`)

  return (
    <>
      {uniqueFilteredSessions.map((session) => {
        const sessions = filteredSessions.filter(
          (session) => section.moduleCodes.includes(session.module_code) && session.type === section.type
        )
        const events = progress.progression_events
          .filter(
            session.type === 'parent'
              ? (pe) => mentorIds.includes(pe.mentor_id || 0)
              : (pe) => pe.participant_uid === `sas-${progress.id}`
          )
          .filter(
            (pe) =>
              pe.type === 'MODULE_EVENT' &&
              pe.category === 'complete' &&
              eventSubcategories.includes(pe.subcategory || '')
          )
        return (
          <tr key={session.id}>
            <td style={backgroundStyles[section.type]}>
              <strong>
                {section.type === 'cadet' ? 'Child Meeting - ' : 'Parent Meeting - '}
                {getModuleTitle(session.module_code)}
              </strong>
            </td>
            <td style={backgroundStyles[section.type]}>
              <Row justifyContent="center">
                {intersperseSpacers(
                  sessions.map((session, i) => (
                    <Tooltip
                      key={i}
                      tooltipStyle={{ width: 160, padding: '7px 9px' }}
                      content={
                        session.group
                          ? `Part of a different group (${getGroupPublicName(session.group)})`
                          : `Part of this group`
                      }>
                      {!session.concluded ? '-' : events.length > 0 ? <TickIcon /> : <CrossIcon />}
                    </Tooltip>
                  )),
                  'xs'
                )}
              </Row>
            </td>
          </tr>
        )
      })}
    </>
  )
}

const CrossIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.5" cy="11.5" r="11" fill="#fff" stroke="#BDC3E0" />
    <path
      d="M6.86091 15.6993L8.20724 17.0457L12.167 13.0859L16.0674 16.9863L17.3148 15.7389L13.4144 11.8385L17.3544 7.89853L16.008 6.5522L12.068 10.4922L8.16765 6.5918L6.92031 7.83914L10.8207 11.7395L6.86091 15.6993Z"
      fill="#CE1219"
    />
  </svg>
)

const TickIcon = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11.5" cy="11.5" r="11" fill="#fff" stroke="#BDC3E0" />
    <path d="M6.05264 10.4912L10.2895 16.9474L16.9474 4.8421" stroke="#419636" strokeWidth="2" />
  </svg>
)

const TeacherTipsheetSection: React.FC<{
  section: TipsheetProgressSection
  progress: ProfileProgress
  sessions: SessionEntity[]
  externalSessions?: SessionEntity[]
  tipsheetMentors: MentorEntity[]
}> = ({ section, progress, sessions, externalSessions, tipsheetMentors }) => {
  const allSessions = externalSessions ? [...sessions, ...externalSessions] : sessions
  const cadetSessions = uniqBy(
    allSessions.filter((session) => section.moduleCodes.includes(session.module_code) && session.type === 'cadet'),
    'module_code'
  )

  if (cadetSessions.length === 0 || tipsheetMentors.length === 0) {
    return (
      <tr>
        <td style={backgroundStyles['teacher']}>
          <strong>Tip Sheets - Module {section.moduleCodes[0]}</strong>
        </td>
        <td style={backgroundStyles['teacher']}>-</td>
      </tr>
    )
  }

  const mentorIds = tipsheetMentors.map((mentor) => mentor.id)
  const allEvents = progress.progression_events.filter(
    (pe) =>
      pe.mentor_id &&
      mentorIds.includes(pe.mentor_id) &&
      pe.type === 'TIPSHEET_CONFIRM_READ' &&
      (pe.category === 'tipsheet' || pe.category === 'tipsheet-teacher') &&
      section.moduleCodes.includes(pe.subcategory as AnyModuleCode)
  )

  return (
    <>
      {cadetSessions.map((session) => {
        return (
          <tr key={session.id}>
            <td style={backgroundStyles['teacher']}>
              <strong>Teacher Tip Sheets - Module {session.module_code}</strong>
            </td>
            <td style={backgroundStyles['teacher']}>
              {!session.concluded ? (
                '-'
              ) : (
                <Row justifyContent="center">
                  {intersperseSpacers(
                    tipsheetMentors.map((mentor, i) => {
                      const events = allEvents.filter((pe) => pe.mentor_id === mentor.id)
                      return (
                        <Tooltip
                          key={i}
                          content={`Teacher Mentor:\n${mentorName(mentor)}`}
                          containerStyle={{ lineHeight: 0 }}
                          tooltipStyle={{ minWidth: 120 }}>
                          {events.length > 0 ? <TickIcon /> : <CrossIcon />}
                        </Tooltip>
                      )
                    }),
                    's'
                  )}
                </Row>
              )}
            </td>
          </tr>
        )
      })}
    </>
  )
}

const CommunityTipsheetSection: React.FC<{
  section: TipsheetProgressSection
  progress: ProfileProgress
  sessions: SessionEntity[]
  externalSessions?: SessionEntity[]
  tipsheetMentors: MentorEntity[]
}> = ({ section, progress, sessions, externalSessions, tipsheetMentors }) => {
  const allSessions = externalSessions ? [...sessions, ...externalSessions] : sessions
  const cadetSessions = uniqBy(
    allSessions.filter((session) => section.moduleCodes.includes(session.module_code) && session.type === 'cadet'),
    'module_code'
  )

  if (cadetSessions.length === 0 || tipsheetMentors.length === 0) {
    return <></>
  }

  const mentorIds = tipsheetMentors.map((mentor) => mentor.id)
  const allEvents = progress.progression_events.filter(
    (pe) =>
      pe.mentor_id &&
      mentorIds.includes(pe.mentor_id) &&
      pe.type === 'TIPSHEET_CONFIRM_READ' &&
      pe.category === 'tipsheet-community' &&
      section.moduleCodes.includes(pe.subcategory as AnyModuleCode)
  )

  return (
    <>
      {cadetSessions.map((session) => {
        return (
          <tr key={session.id}>
            <td style={backgroundStyles['community']}>
              <strong>Community Activity Tip Sheets - Module {session.module_code}</strong>
            </td>
            <td style={backgroundStyles['community']}>
              {!session.concluded ? (
                '-'
              ) : (
                <Row justifyContent="center">
                  {intersperseSpacers(
                    tipsheetMentors.map((mentor, i) => {
                      const events = allEvents.filter((pe) => pe.mentor_id === mentor.id)
                      return (
                        <Tooltip
                          key={i}
                          content={`Community Mentor:\n${mentorName(mentor)}`}
                          containerStyle={{ lineHeight: 0 }}
                          tooltipStyle={{ minWidth: 120 }}>
                          {events.length > 0 ? <TickIcon /> : <CrossIcon />}
                        </Tooltip>
                      )
                    }),
                    's'
                  )}
                </Row>
              )}
            </td>
          </tr>
        )
      })}
    </>
  )
}

function mentorName(mentor: MentorEntity) {
  return [mentor.first_name, mentor.last_name].filter(Boolean).join(' ')
}

const SkillTrackerSection: React.FC<{ section: SkillTrackerProgressSection; progress: ProfileProgress }> = ({
  section,
  progress,
}) => {
  const skillTracker = progress.skill_tracker
  const moduleCodes = section.moduleCodes.filter((moduleCode) => {
    return (skillTracker?.modules || []).find((module) => module.presetModuleId === moduleCode)
  })

  if (!moduleCodes.length || !skillTracker) {
    return (
      <tr>
        <td>
          {skillTrackerIcon}
          <strong>Skill Tracker {section.moduleCodes[0]}</strong>
        </td>
        <td>-</td>
      </tr>
    )
  }
  return (
    <>
      {moduleCodes.map((moduleCode, i) => {
        return (
          <tr key={i}>
            <td>
              {skillTrackerIcon}
              <strong>
                Skill Tracker{' '}
                {skillTracker.workflow === 'whole' && moduleCodes.length === 1 ? section.moduleCodes[0] : moduleCode}
              </strong>
            </td>
            <td>
              <SkillTrackerSummary moduleCode={moduleCode} skillTracker={skillTracker} />
            </td>
          </tr>
        )
      })}
    </>
  )
}

const SkillTrackerSummary: React.FC<{ moduleCode: string; skillTracker: SkillTrackerState }> = ({
  moduleCode,
  skillTracker,
}) => {
  const module = skillTracker.modules.find((module) => module.presetModuleId === moduleCode)
  const skills = getConfirmedModuleSkills(moduleCode, skillTracker)
  const allModuleSkillUses = getSkillUsesForSkills(skillTracker.skillUses, skills)
  const skillUses = allModuleSkillUses.filter((skillUse) => skillUse.approved && !skillUse.bonus)
  const bonusSkillUses = allModuleSkillUses.filter((skillUse) => skillUse.bonus)
  const totalDailyTarget = skills.reduce((acc, skill) => acc + (skill.dailyTarget || 0), 0)
  const totalModuleTarget = totalDailyTarget * (module?.duration || 7)

  return (
    <>
      <Row alignItems="center">
        <BarContainer>
          <Bar>
            {locations.map((location, i) => {
              const locationSkillUses = skillUses.filter((skillUse) => skillUse.location === location.value)
              return (
                <BarSegment
                  key={i}
                  color={location.color}
                  value={locationSkillUses.length}
                  total={totalModuleTarget}
                  title={`${location.label}: ${locationSkillUses.length}`}
                />
              )
            })}
          </Bar>
        </BarContainer>
        <Spacer />
        <Text>{`${skillUses.length}/${totalModuleTarget}`}</Text>
        {bonusSkillUses.length > 0 && (
          <>
            <Spacer />
            <Text size={32}>
              <span role="img" aria-label="Bonus points">
                ⭐️
              </span>
            </Text>
            <Spacer size="xs" />
            <Text>{bonusSkillUses.length}</Text>
          </>
        )}
      </Row>
    </>
  )
}

const BarContainer = styled(Column)`
  flex: auto;
  background: white;
  border: 1px solid #abb4db;
  border-radius: 10px;
  box-shadow: 0px 1px 0px 0px #fff;
  overflow: hidden;
`

const Bar = styled(Row)`
  border: 2px solid white;
  border-radius: 10px;
  height: 50px;
  background-color: white;
  overflow: hidden;

  /* Fixed border overflow issue on Chrome https://gist.github.com/adamcbrewer/5859738 */
  -webkit-mask-image: radial-gradient(circle, white 100%, black 100%);
`

const BarSegment = styled(Row)<{ index?: number; color?: string; value: number; total: number }>`
  flex: 0 0 ${(p) => (p.value / p.total) * 100}%;
  height: 100%;
  overflow: hidden;
  background-color: ${(p) =>
    p.color ||
    (p.index !== undefined ? p.theme.emotionometerColors[p.index % p.theme.emotionometerColors.length] : 'black')};
`
