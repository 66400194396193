import { ActionTypes } from './actionTypes'
import { IdField, UidField, OrderField, TitleField, OwnerOccupierFields } from '../types'
import { CadetModuleCode } from '../dashboard/moduleCodes'
import {
  BlockSection,
  DividerSection,
  DownloadResourceSection,
  InputSection,
  ListSection,
  MediaGridSection,
  TextSection,
} from '../session/types'

export interface TipsheetState {
  currentStepUid: string | null
}

export interface TipsheetStateHookObject {
  state: TipsheetState
  tipsheetData: false | TipsheetData
  setTipsheetData: React.Dispatch<React.SetStateAction<this['tipsheetData']>>
  dispatch: (action: ActionTypes) => void
  previewing: boolean
  setPreviewing: React.Dispatch<React.SetStateAction<this['previewing']>>
  previewState: PreviewState
  setPreviewState: React.Dispatch<React.SetStateAction<this['previewState']>>
}

export interface PreviewState {
  stepIndex: number
}

export interface TipsheetData {
  module: TipsheetEntity
}

export type TipsheetType = 'teacher' | 'community'
export type TipsheetLanguage = 'english' | 'spanish' | 'vietnamese'

/** CakePHP Model: Tipsheets */
export interface TipsheetEntity extends IdField, UidField {
  title: string
  public_title?: string
  tipsheet_steps: TipsheetStepEntity[]
  type?: TipsheetType
  module_code: CadetModuleCode
  language?: TipsheetLanguage
  theme?: 'gray' | 'blue' | 'pink' | 'orange'
}

/** CakePHP Model: TipsheetSteps */
export interface TipsheetStepEntity extends IdField, UidField, OrderField, SectionContainer {
  title?: string
  subheading?: string
  hide_header: boolean
}

/** CakePHP Model: TipsheetBorderedSections */
export interface BorderedSection
  extends Section,
    IdField,
    OrderField,
    TitleField,
    Exclude<SectionContainer, 'bordered_sections'> {
  theme: 'purple' | 'blue' | 'green' | 'orange'
}

/** CakePHP Model: TipsheetEmbeddedActivitySections */
export interface TipsheetEmbeddedActivitySection extends Section {
  activity: 'emotionometer-anger' | 'emotionometer-anxiety' | 'relaxation-gadgets'
  direction_text: string
  unauthorised_text: string
}

/**
 * Section content types
 */

export const sectionProperties = [
  'divider_sections',
  'list_sections',
  'media_grid_sections',
  'text_sections',
  'heading_divider_sections',
  'block_sections',
  'input_sections',
  'bordered_sections',
  'tipsheet_embedded_activity_sections',
  'download_resource_sections',
] as const

export type SectionProperty = (typeof sectionProperties)[number]

export interface HeadingDividerSection extends Section {
  heading: string
}

export interface SectionObject {
  property: SectionProperty
  section: SectionContainer[this['property']][0]
  index: number
}

export interface SectionPropsBase extends SectionObject {}

type SectionContainerShape = { [key in SectionProperty]: Section[] }
export interface SectionContainer extends SectionContainerShape {
  divider_sections: DividerSection[]
  list_sections: ListSection[]
  media_grid_sections: MediaGridSection[]
  text_sections: TextSection[]
  heading_divider_sections: HeadingDividerSection[]
  block_sections: BlockSection[]
  input_sections: InputSection[]
  bordered_sections: BorderedSection[]
  tipsheet_embedded_activity_sections: TipsheetEmbeddedActivitySection[]
  download_resource_sections: DownloadResourceSection[]
}

export type ContentSection =
  | DividerSection
  | ListSection
  | MediaGridSection
  | TextSection
  | HeadingDividerSection
  | BlockSection
  | InputSection
  | BorderedSection
  | TipsheetEmbeddedActivitySection
  | DownloadResourceSection

export interface Section extends IdField, OwnerOccupierFields, OrderField, TitleField {}
