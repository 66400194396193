import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { GroupEntity, SessionEntity, MentorEntity } from 'shared/dashboard/types'
import { NavRouteProps } from 'dashboards/types'

import { H3, P, Spacer, CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L, Padding, Row } from 'common/ui'
import { Page } from 'dashboards/common/Page'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { CompactCells, Cells, Cell, CellInner } from 'dashboards/common/Cell'
import { LinkButton } from 'common/LinkButton'
import { SessionCell } from 'dashboards/common/SessionCell'
import { MentorCell } from 'dashboards/common/MentorProfileCell'
import { SpinnerWithLabel, Spinner } from 'common/Spinner'
import { MentorRemoveModal } from './groupOverview/MentorRemoveModal'
import { UserCell } from 'dashboards/common/UserCell'

import { postJson } from 'utils/apiUtils'
import { getGroupPublicName } from 'utils/titleUtils'
import {
  sessionsFilterFuture,
  sessionsFilterPast,
  sessionsFilterCatchUp,
  sessionsFilterCurrentUnfinalized,
} from 'dashboards/utils/reducers'
import { groupAtCapacity, getGroupCapacity } from 'dashboards/utils/facilitatorUtils'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { useGenericUser } from 'app/useGenericUser'
import { useUserState } from 'app/UserState'
import { useFacDashData } from './FacilitatorDashboardState'
import { MobileModalPanel } from 'app/MobileMessageModal'
import { HideForPrint } from 'print/ui-print'
import { PrintButton } from 'dashboards/common/PrintButton'
import { RouteNotFound } from 'dashboards/common/RouteNotFound'
import { MentorMoveModal } from './groupOverview/MentorMoveModal'
import { facilitatorDashboardBaseUrl } from './constants'

export const GroupOverview: React.FC<NavRouteProps> = ({ route }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { groupUid } = useParams<{ groupUid: string }>()
  const user = useGenericUser()
  const { accessToken, drupalProfile } = useUserState()
  const [group, { loading, errorLoading, fetch: refetchGroup }] = useEndpoint<GroupEntity>(`/api/v1/groups/${groupUid}`)

  const [sessions, { loading: loadingSessions, errorLoading: errorLoadingSessions, fetch: refetchSessions }] =
    useEndpoint<SessionEntity[]>(`/api/v1/groups/${groupUid}/sessions`)
  const catchUpSessions = sessionsFilterCatchUp(sessions || [])
  const futureSessions = sessionsFilterFuture(sessions || [])
  const pastSessions = sessionsFilterPast(sessions || [])
  const currentUnfinalizedSessions = sessionsFilterCurrentUnfinalized(sessions || [])

  const [removePrimaryMentor, setRemovePrimaryMentor] = useState<MentorEntity | undefined>()
  const [cadetBeingMoved, setCadetBeingMoved] = useState<MentorEntity | undefined>()

  const [providers, loadingProviders] = useFacDashData('providers', [])
  const provider = providers.find((provider) => provider.id === group?.provider_uid)

  const handleMoveSubmission = (moved: boolean) => {
    setCadetBeingMoved(undefined)
    if (moved) refetchGroup()
  }

  const handleConfirmRemove = (primaryMentor: MentorEntity) => {
    postJson(`/api/v1/mentors/${primaryMentor.uid}/banish`, {}, getAuthRequestParams(accessToken), { retries: 0 })
      .then(() => {
        refetchGroup()
      })
      .catch((e) => {
        alert('Unable to remove cadet/primary mentor')
        console.warn(e)
      })
  }

  if (errorLoading)
    return (
      <Page route={route}>
        <HeaderHr
          children={<span style={{ color: 'red' }}>There was an error fetching the group</span>}
          sideRoute={`${facilitatorDashboardBaseUrl}/groups`}
          sideLabel=" < Back to My Groups"
        />
      </Page>
    )

  if (loading || !group)
    return (
      <Page route={route}>
        <SpinnerWithLabel color="#925BED" label="One moment please..." />
      </Page>
    )

  if (!loading && group.facilitator_id.toString() !== drupalProfile?.user_id) {
    return (
      <Page route={route}>
        <RouteNotFound />
      </Page>
    )
  }

  return (
    <>
      <Page
        route={route}
        title={
          <span>
            {group.name}{' '}
            <LinkButton
              to={`${facilitatorDashboardBaseUrl}/groups/${groupUid}/edit`}
              size="xs"
              marginLeft="s"
              children="Edit"
            />
            <br />
            <small style={{ fontSize: '0.75em', fontWeight: 600 }}>{getGroupPublicName(group)}</small>
          </span>
        }
        sideRoute={`${facilitatorDashboardBaseUrl}/groups`}
        sideLabel="Back to Groups"
        sideFragment={
          <>
            <Spacer />
            <PrintButton />
          </>
        }>
        {user.roles.indexOf('sas-sg senior facilitator') >= 0 && group.cofacilitator_id && (
          <>
            <HeaderHr children={`Co-facilitator`} />
            <CompactCells>
              <UserCell fetchUrl={`/api/v1/groups/${groupUid}/user/${group.cofacilitator_id}`} />
            </CompactCells>
            <Spacer size="m" />
          </>
        )}
        {user.roles.indexOf('sas-sg senior facilitator') >= 0 && group.assistant_id && (
          <>
            <HeaderHr children={`Assistant`} />
            <CompactCells>
              <UserCell fetchUrl={`/api/v1/groups/${groupUid}/user/${group.assistant_id}`} />
            </CompactCells>
            <Spacer size="m" />
          </>
        )}

        <HeaderHr children="Provider" />
        {loadingProviders ? (
          <SpinnerWithLabel label="Loading providers..." color="#925BED" />
        ) : provider ? (
          <Cells>
            <Cell key={provider.id} style={{ minHeight: 50 }}>
              <CellInner>
                <H3 children={provider.name} />
              </CellInner>
            </Cell>
          </Cells>
        ) : (
          <P>Sorry we couldn't locate this groups provider</P>
        )}
        <Spacer />

        <HeaderHr>
          Cadets{' '}
          <span style={{ opacity: 0.5 }}>
            ({group.primary_mentors.length}/{getGroupCapacity(group, user)})
          </span>
        </HeaderHr>
        {group.primary_mentors.length > 0 ? (
          <>
            <Cells>
              {group.primary_mentors.map((mentor, i) => (
                <MentorCell
                  key={mentor.uid}
                  index={i}
                  context="group-overview"
                  mentor={mentor}
                  groupUid={groupUid}
                  moveButtonDisabled={loadingSessions || errorLoadingSessions || currentUnfinalizedSessions.length > 0}
                  moveButtonLabel={loadingSessions ? <Spinner /> : `Move`}
                  onUpdate={() => refetchGroup()}
                  onReloadRequested={() => refetchGroup()}
                  onRemoveRequested={() => setRemovePrimaryMentor(mentor)}
                  onMoveRequested={() => setCadetBeingMoved(mentor)}
                />
              ))}
            </Cells>
            {groupAtCapacity(group, user) ? (
              <P>You've reached the maximum allowed cadets for your current group.</P>
            ) : (
              <Spacer size="s" />
            )}
            <HideForPrint>
              <LinkButton
                size="xs"
                to={`${facilitatorDashboardBaseUrl}/groups/${groupUid}/cadets/new`}
                theme="blue"
                style={{ display: 'inline-block' }}
                children="+ Add Cadet"
                disabled={groupAtCapacity(group, user)}
              />
            </HideForPrint>
          </>
        ) : (
          <>
            <P>(Group is currently empty)</P>
            <LinkButton
              size="xs"
              to={`${facilitatorDashboardBaseUrl}/groups/${groupUid}/edit`}
              theme="blue"
              children="Edit Group"
            />
            <Spacer size="m" />
          </>
        )}
        <Spacer size="l" />

        <HeaderHr children={`Scheduled Meetings`} />
        {loadingSessions ? (
          <SpinnerWithLabel label="Loading meetings..." />
        ) : !sessions || errorLoadingSessions ? (
          <P>An error occurred loading meetings...</P>
        ) : futureSessions.length > 0 ? (
          <Cells>
            {futureSessions.map((session) => (
              <SessionCell
                key={session.uid}
                userType="facilitator"
                context="future"
                session={session}
                group={group}
                hideGroupTitle
                onUpdate={() => refetchSessions()}
                preventStart={
                  catchUpSessions.length > 0 && group
                    ? !!catchUpSessions.find(({ group_id }) => group_id === group.id)
                    : false
                }
                preventStartCallback={() => setModalOpen(true)}
              />
            ))}
          </Cells>
        ) : (
          <P>No meetings scheduled for group</P>
        )}

        {(user.roles.indexOf('sas-sg facilitator') >= 0 || user.roles.indexOf('sas-sg senior facilitator') >= 0) && (
          <HideForPrint>
            <LinkButton
              marginTop="m"
              size="s"
              children={`Schedule a meeting`}
              to={`${facilitatorDashboardBaseUrl}/meetings/new/${group.uid}`}
              style={{ display: 'inline-block' }}
            />
          </HideForPrint>
        )}
        <Spacer size="l" />

        {pastSessions.length > 0 && (
          <>
            <HeaderHr children={`Past Meetings`} />
            <Cells>
              {pastSessions.reverse().map((session) => (
                <SessionCell key={session.uid} userType="facilitator" context="past" session={session} />
              ))}
            </Cells>
            <Spacer size="m" />
          </>
        )}
      </Page>
      <MentorRemoveModal
        isOpen={!!removePrimaryMentor}
        mentor={removePrimaryMentor}
        onConfirmDelete={() => {
          if (removePrimaryMentor) handleConfirmRemove(removePrimaryMentor)
          setRemovePrimaryMentor(undefined)
        }}
        onRequestClose={() => {
          setRemovePrimaryMentor(undefined)
        }}
      />
      {!!cadetBeingMoved && (
        <MentorMoveModal
          isOpen
          mentor={cadetBeingMoved}
          providerUid={group?.provider_uid}
          disableGroupUids={[groupUid]}
          onSubmit={handleMoveSubmission}
        />
      )}
      <MobileModalPanel
        zIndex={1200}
        isOpen={modalOpen}
        panelProps={{ flair: [CUT_TL, CUT_TR, CUT_BR, CUT_BL, TAB_B_L, TAB_T_L] }}>
        <Padding size="l">
          <P style={{ maxWidth: 500 }}>
            Please stop all Catch-up Cadet Club Meetings before starting this new Club Meeting.
            <br />
            <br />
            If cadet’s have already started to log into the open Catch-up meeting, they may need to exit and log in
            again to join this new meeting with you.
          </P>
          <Row justifyContent="center" style={{ paddingBottom: 20 }}>
            <LinkButton size="s" children="Group Meetings" to={`${facilitatorDashboardBaseUrl}/meetings`} />
          </Row>
        </Padding>
      </MobileModalPanel>
    </>
  )
}
