import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { NavRouteProps } from 'dashboards/types'
import { MentorEntity, MentorEntityNew, GroupEntity } from 'shared/dashboard/types'

import { P, Button, Column, Spacer, Hr, H3 } from 'common/ui'

import { Page } from 'dashboards/common/Page'
import { HeaderHr } from 'dashboards/common/HeaderHr'
import { MentorEditFields, CellHeader } from '../common/MentorProfileCell'
import { Form, Props as FormProps } from 'dashboards/common/Form'
import { FormFieldDataProps, FormField } from 'dashboards/common/FormField'
import { Cell } from 'dashboards/common/Cell'
import { Checkbox } from 'common/Checkbox'
import { LinkButton } from 'common/LinkButton'
import { SpinnerWithLabel } from 'common/Spinner'
import { ProviderPlacesCount } from '../common/ProviderPlacesCount'

import { facilitatorDashboardBaseUrl } from './constants'
import { getAuthRequestParams } from 'dashboards/utils/authUtils'
import { useGenericUser } from 'app/useGenericUser'
import { useUserState } from 'app/UserState'
import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { groupAtCapacity, getMentorIndex } from 'dashboards/utils/facilitatorUtils'

export const CadetAdd: React.FC<
  NavRouteProps & {
    editMode?: boolean
    formProps?: Partial<FormProps<MentorEntity, MentorEntityNew>>
  }
> = ({ route, editMode, formProps = {} }) => {
  const { groupUid } = useParams<{ groupUid: string }>()
  const history = useHistory()
  const user = useGenericUser()
  const { accessToken } = useUserState()

  const [group, { loading: loadingGroup }] = useEndpoint<GroupEntity>(`/api/v1/groups/${groupUid}`)

  const onSubmitSuccess = (savedMentor: MentorEntity) => {
    history.replace(`${facilitatorDashboardBaseUrl}/groups/${groupUid}/cadets`)
  }
  return (
    <Page
      route={route}
      sideLabel="Back to Group Overview"
      sideRoute={`${facilitatorDashboardBaseUrl}/groups/${groupUid}/cadets`}>
      <Spacer size="m" />
      {loadingGroup || !group ? (
        <SpinnerWithLabel label="Loading data..." />
      ) : !editMode && groupAtCapacity(group, user) ? (
        <>
          <P>You've reached the maximum allowed cadets for your current group.</P>
          <LinkButton to={`${facilitatorDashboardBaseUrl}/groups/${groupUid}/cadets`} children="Back" />
        </>
      ) : (
        <>
          {!editMode && (
            <>
              <HeaderHr
                children={`Allocate Cadet Places`}
                sideContent={<ProviderPlacesCount providerUid={group.provider_uid} showNegativeOne />}
              />
              <P>
                To allocate a Cadet Place to this group an appropriate adult must provide consent for the child's
                participation. Enter the consenting adult details and send a registration request.
              </P>
            </>
          )}
          <Form<MentorEntity, MentorEntityNew>
            defaultValue={{ group_id: group.id, primary_licence_holder: true, _auto_trigger_email: !editMode }}
            initialDataUrl={`/api/v1/mentors/new`}
            initialDataRequestOptions={getAuthRequestParams(accessToken)}
            submitUrl={`/api/v1/mentors/save`}
            submitRequestOptions={{ ...getAuthRequestParams(accessToken), method: 'POST' }}
            onSubmitSuccess={onSubmitSuccess}
            {...formProps}
            children={({
              loading,
              errorLoading,
              submitting,
              formData,
              setFormData,
              validationErrors,
              errorSubmitting,
            }) => {
              if (loading) return <SpinnerWithLabel color="#925BED" label="One moment please..." />
              if (errorLoading) return <P>An error occurred while loading data, try refreshing.</P>

              const hasErrors = Object.values(validationErrors).length > 0

              const formFieldDataProps: FormFieldDataProps<MentorEntityNew> = {
                formData,
                validationErrors,
                setFormData,
              }
              return (
                <>
                  <Cell error={hasErrors}>
                    <CellHeader>
                      <H3>
                        Cadet Place{' '}
                        {editMode
                          ? getMentorIndex(group.primary_mentors, formData) + 1
                          : group.primary_mentors.length + 1}
                        :
                      </H3>
                    </CellHeader>
                    <Column padding="10px 12px">
                      <MentorEditFields isConsentingAdult formDataProps={formFieldDataProps} />
                    </Column>
                    {!editMode && (
                      <>
                        <Hr />
                        <Column padding="10px 12px">
                          <FormField<MentorEntityNew, boolean>
                            {...formFieldDataProps}
                            select="_auto_trigger_email"
                            defaultValue={!editMode}
                            children={({ value, onChange }) => (
                              <Checkbox size="s" checked={!!value} onChange={onChange}>
                                Send invitation email {formData.email && `to ${formData.email}`}
                              </Checkbox>
                            )}
                          />
                        </Column>
                        <Hr />
                        <Column padding="10px 12px">
                          <P style={{ margin: 0 }}>
                            Note: A Cadet Place from your SAS Provider inventory will be used by adding this Cadet’s
                            consenting adult.
                          </P>
                        </Column>
                      </>
                    )}
                  </Cell>
                  <Spacer size="m" />
                  {Object.values(validationErrors).length > 0 && (
                    <P style={{ color: 'red' }}>There are validation errors, please check the fields above.</P>
                  )}
                  {errorSubmitting && <P style={{ color: 'red' }} children={errorSubmitting} />}
                  <Button
                    type="submit"
                    size="m"
                    disabled={submitting}
                    children={submitting ? 'Submitting...' : formData._auto_trigger_email ? 'Save & Send' : 'Save'}
                  />
                </>
              )
            }}
          />
        </>
      )}
    </Page>
  )
}
