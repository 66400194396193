/* eslint-disable @typescript-eslint/no-unused-vars */
import qs from 'qs'
import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useParams } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'

import { userTypeThemeMap } from 'dashboards/public/PublicQuestionnaire'
import { NavRouteProps } from 'dashboards/types'
import { UPDATE_STATE } from 'shared/questionnaires/actionTypes'
import {
  Questionnaire,
  QuestionnaireBooklet,
  QuestionnaireSummarySaved,
  QuestionnaireKey,
  questionnaireKeyLookup,
} from 'shared/questionnaires/types'

import { SpinnerWithLabel } from 'common/Spinner'
import { AppBackground, P, Padding } from 'common/ui'

import { observationalCodeQuestionnaire } from 'dashboards/constant/observationalCode'
import { Panel, QuestionnaireView as QuestionnaireContent } from 'questionnaires/Questionnaire'
import { QuestionnaireStateProvider, useQuestionnaireState } from 'questionnaires/QuestionnaireState'

import { useEndpoint } from 'dashboards/utils/endpointHooks'
import { InputQuestionWrapper } from 'questionnaires/sections/InputSection'
import { RichTextContainer } from 'session/common/RichText'
import { useFacDashData } from './FacilitatorDashboardState'

export const PrintQuestionnaire: React.FC<NavRouteProps> = (props) => (
  <QuestionnaireStateProvider>
    <PrintQuestionnaireMain {...props} />
  </QuestionnaireStateProvider>
)

export const PrintQuestionnaireMain: React.FC<NavRouteProps> = ({ route }) => {
  const { cadetMentorId, mentorId, questionnaireBooklet, questionnaireKey } = useParams<{
    cadetMentorId: string
    mentorId: string
    questionnaireBooklet: string
    questionnaireKey: QuestionnaireKey
  }>()
  const isObservationCode = questionnaireKey === 'observational-code'
  if (!(questionnaireKey in questionnaireKeyLookup)) {
    alert(
      `Unknown questionnaireKey in PrintQuestionnaireMain: ${questionnaireKey}\nPlease report this error to support!`
    )
    throw new Error(
      `Unknown questionnaireKey in PrintQuestionnaireMain: ${questionnaireKey}\nPlease report this error to support!`
    )
  }
  const { type: questionnaireType, userType } = questionnaireKeyLookup[questionnaireKey as QuestionnaireKey]
  const [questionnaireSubmissions, { loading: loadingQuestionnaireSubmissions }] = useEndpoint<
    QuestionnaireSummarySaved[]
  >(
    `/api/v1/questionnaires?${qs.stringify({
      cadet_mentor_id: cadetMentorId,
      mentor_id: mentorId === 'cadet' ? undefined : mentorId,
      type: questionnaireKey,
      booklet: questionnaireBooklet,
    })}`
  )
  const [cmsQuestionnaire, { loading: loadingQuestionnaire, errorLoading: errorLoadingQuestionnaire }] =
    useEndpoint<Questionnaire>(
      isObservationCode ? null : `/api/v1/questionnaires/${questionnaireBooklet}/${userType}/${questionnaireType}`
    )

  return (
    <QuestionnairePrintView
      loading={loadingQuestionnaire}
      loadingUserData={loadingQuestionnaireSubmissions}
      errorLoading={errorLoadingQuestionnaire}
      questionnaireKey={questionnaireKey as QuestionnaireKey}
      questionnaireBooklet={parseInt(questionnaireBooklet) as QuestionnaireBooklet}
      questionnaireData={cmsQuestionnaire || undefined}
      questionnaireUserData={
        questionnaireSubmissions && questionnaireSubmissions.length > 0 ? questionnaireSubmissions[0] : undefined
      }
    />
  )
}

export const PrintOrphanedQuestionnaire: React.FC<NavRouteProps> = (props) => (
  <QuestionnaireStateProvider>
    <PrintOrphanedQuestionnaireMain {...props} />
  </QuestionnaireStateProvider>
)

export const PrintOrphanedQuestionnaireMain: React.FC<NavRouteProps> = ({ route }) => {
  const [providers, loadingProviders] = useFacDashData('providers', [])
  const { orphanId, questionnaireBooklet, questionnaireKey } = useParams<{
    orphanId: string
    questionnaireBooklet: string
    questionnaireKey: string
  }>()
  const isObservationCode = (questionnaireKey as QuestionnaireKey) === 'observational-code'
  if (!(questionnaireKey in questionnaireKeyLookup)) {
    alert(
      `Unknown questionnaireKey in PrintOrphanedQuestionnaireMain: ${questionnaireKey}\nPlease report this error to support!`
    )
    throw new Error(
      `Unknown questionnaireKey in PrintOrphanedQuestionnaireMain: ${questionnaireKey}\nPlease report this error to support!`
    )
  }
  const { type: questionnaireType, userType } = questionnaireKeyLookup[questionnaireKey as QuestionnaireKey]
  const [
    questionnaireSubmissions,
    { loading: loadingQuestionnaireSubmissions, errorLoading: errorLoadingQuestionnaireSubmissions },
  ] = useEndpoint<QuestionnaireSummarySaved[]>(
    !loadingProviders && providers.length > 0
      ? `/api/v1/questionnaires/orphaned?${qs.stringify(
          {
            orphan_id: orphanId,
            provider_uid: providers.map(({ id }) => id),
          },
          { arrayFormat: 'brackets', encodeValuesOnly: true }
        )}`
      : null,
    []
  )
  const [cmsQuestionnaire, { loading: loadingQuestionnaire, errorLoading: errorLoadingQuestionnaire }] =
    useEndpoint<Questionnaire>(
      isObservationCode ? null : `/api/v1/questionnaires/${questionnaireBooklet}/${userType}/${questionnaireType}`
    )

  return (
    <QuestionnairePrintView
      loading={loadingQuestionnaire}
      loadingUserData={loadingQuestionnaireSubmissions}
      errorLoading={errorLoadingQuestionnaire || errorLoadingQuestionnaireSubmissions}
      questionnaireKey={questionnaireKey as QuestionnaireKey}
      questionnaireBooklet={parseInt(questionnaireBooklet) as QuestionnaireBooklet}
      questionnaireData={cmsQuestionnaire || undefined}
      questionnaireUserData={
        questionnaireSubmissions && questionnaireSubmissions.length > 0 ? questionnaireSubmissions[0] : undefined
      }
    />
  )
}

interface QuestionnairePrintViewProps {
  loading: boolean
  loadingUserData: boolean
  errorLoading: boolean
  questionnaireKey: QuestionnaireKey
  questionnaireBooklet: QuestionnaireBooklet
  questionnaireData?: Questionnaire
  questionnaireUserData?: QuestionnaireSummarySaved
}

export const QuestionnairePrintView: React.FC<QuestionnairePrintViewProps> = ({
  loading,
  loadingUserData,
  errorLoading,
  questionnaireKey,
  questionnaireBooklet,
  questionnaireData,
  questionnaireUserData,
}) => {
  const printTriggered = useRef<boolean>(false)
  const {
    dispatch,
    state: userData,
    setQuestionnaireData,
    questionnaireData: storedQuestionnaireData,
  } = useQuestionnaireState()
  if (!(questionnaireKey in questionnaireKeyLookup)) {
    alert(
      `Unknown questionnaireKey in QuestionnairePrintView: ${questionnaireKey}\nPlease report this error to support!`
    )
    throw new Error(
      `Unknown questionnaireKey in QuestionnairePrintView: ${questionnaireKey}\nPlease report this error to support!`
    )
  }
  const { userType } = questionnaireKeyLookup[questionnaireKey]
  const isObservationCode = questionnaireKey === 'observational-code'

  const questionnaire = isObservationCode ? observationalCodeQuestionnaire : questionnaireData

  useEffect(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      if (questionnaire) {
        // console.log('Updating questionnaire data', questionnaire)
        setQuestionnaireData(questionnaire)
      }
      if (questionnaireUserData) {
        // console.log('Updating questionnaire USER data', questionnaireUserData)
        dispatch({ type: UPDATE_STATE, state: questionnaireUserData })
      }
    })
  }, [questionnaireUserData, questionnaire, dispatch, setQuestionnaireData])

  useEffect(() => {
    if (questionnaireUserData && questionnaire && !printTriggered.current) {
      window.dispatchEvent(new Event('onbeforeprint'))
      printTriggered.current = true
      // NOTE: There is a chromium bug that causes chrome to freeze on the "Loading preview" print dialogue
      // This only occurs when running from dev environment
      // https://github.com/facebook/react/issues/16734#issuecomment-674549232
      // More resources:
      // https://stackoverflow.com/questions/6460630/close-window-automatically-after-printing-dialog-closes/37141437
      setTimeout(() => {
        window.print()
        window.dispatchEvent(new Event('onafterprint'))
        window.close()
      }, 500)
    }
  }, [questionnaireUserData, questionnaire])

  return (
    <>
      <AppBackground theme={userTypeThemeMap[userType!]}>
        <Padding size="m" style={{ flexDirection: 'column' }}>
          {(loading && !isObservationCode) || loadingUserData ? (
            <SpinnerWithLabel label="Loading questionnaire content..." />
          ) : errorLoading ? (
            <P>Error loading questionnaire content!</P>
          ) : !storedQuestionnaireData || !userData ? (
            <P>One sec...</P>
          ) : (
            <QuestionnairePrintWrapper>
              <QuestionnaireContent userType={userType!} booklet={questionnaireBooklet} standalone forceReadOnly />
            </QuestionnairePrintWrapper>
          )}
        </Padding>
      </AppBackground>
      <GlobalStyles />
    </>
  )
}

const QuestionnairePrintWrapper = styled.div`
  @page {
    margin: 0.5cm;
  }
  @media print {
    @supports not (-moz-appearance: none) {
      zoom: 0.75;
    }
    @supports (-moz-appearance: none) {
      transform-origin: 0px 0px;
      transform: scale(0.75);
    }

    & label[disabled] {
      opacity: 1 !important;
    }

    & ${Panel} {
      /* break-after: auto; */
      /* page-break-inside: avoid; */
    }

    & .question-bounds {
      display: block;
      break-before: avoid;

      & textarea,
      & input,
      & label {
        break-inside: avoid;
        page-break-inside: avoid;
      }
    }

    & ${InputQuestionWrapper} {
      break-before: avoid;

      & ${RichTextContainer} {
        /* I have no idea why this works, I stumbled into it on accident */
        /* Prevents textareas from overlapping  */
        border: 5px solid transparent;
      }
    }
  }
`

const GlobalStyles = createGlobalStyle`
  @media print {
    noscript, script, style {
      display: none;
    }
  }
`
